import { useEffect } from "react";
import { logAnalyticsEvent } from "../../services/firebaseService";
import BlogCard from "../../components/BlogCard";

const BlogsList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "blog_list_visited" });
  }, []);

  return (
    <div className="flex flex-col items-center px-6 py-8">
      <h1 className="text-4xl font-bold mb-4">Foster Tails</h1>
      <p className="text-lg text-center mb-10 max-w-xl">
        Learn more about fostering dogs, cats, and other pets, and make a
        lasting impact in the animal rescue community.
      </p>

      <div className="flex flex-wrap justify-center max-w-screen-xl gap-6">
        <BlogCard
          id="how-does-pet-fostering-work"
          name="How Pet Fostering Works: What To Know"
          imagePath={require("../../assets/images/paw-high-five.jpeg")}
        />
        <BlogCard
          id="why-become-a-pet-foster"
          name="Why Pet Fostering Matters: Your Role in Changing Lives"
          imagePath={require("../../assets/images/hero-caged-dog.jpeg")}
        />
        <BlogCard
          id="explore-the-world-of-dog-fostering"
          name="Explore the World of Fostering Dogs"
          imagePath={require("../../assets/images/foster-dogs.jpeg")}
        />
        <BlogCard
          id="all-about-cat-fostering"
          name="Kitten and Cat Fostering"
          imagePath={require("../../assets/images/foster-cat.jpeg")}
        />
        <BlogCard
          id="foster-journey"
          name="A Foster Journey: How One Family Gave a Dog a Second Chance"
          imagePath={require("../../assets/images/dog-high-five.jpeg")}
        />
      </div>
    </div>
  );
};

export default BlogsList;
