import React, { useEffect, useState } from "react";
import {
  isAuthenticated,
  logAnalyticsEvent,
} from "../../../services/firebaseService";
import {
  fetchApplications,
  updateOrganization,
} from "../../../services/apiService";
import { Application } from "../../../types/types";
import { IconLogoMark } from "../../../assets/icons/IconLogoMark";
import IconCardNfc from "../../../assets/icons/IconCardNfc";
import useOrganization from "../../../hooks/useOrganization";
import ApplicationCard from "../../../components/ApplicationCard";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../../../components/LabeledInput";

const Dashboard = () => {
  const navigate = useNavigate();
  const organization = useOrganization({});
  const isAuthed = isAuthenticated();
  const [applications, setApplications] = useState<Application[]>([]);

  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState(organization?.name || "");

  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_dashboard_visited" });

    const getApplications = async () => {
      if (!organization) return;

      const res = await fetchApplications({
        organization_id: organization.id,
      });
      if (res.applications) {
        setApplications(res.applications);
      }
    };

    if (isAuthed) {
      getApplications();
    }
  }, [isAuthed, organization]);

  const changeOrganization = async () => {
    try {
      if (organization && name) {
        const res = await updateOrganization({
          id: organization.id,
          email: organization.email,
          name: name,
        });
        console.log(res.error, res.organization);
        setIsEditingName(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="p-5">
      <h1 className="text-secondary-400 text-3xl font-bold pb-4">Dashboard</h1>

      <div className="flex flex-col md:flex-row md:space-x-8">
        <div className="flex flex-col justify-between gap-4">
          {/* Organization Card */}
          <div className="bg-secondary-400 p-4 rounded-lg max-h-[150px] flex flex-col justify-between min-w-xl">
            <div className="flex justify-between py-2">
              <IconLogoMark fill="#FFFFFF" />
              <IconCardNfc />
            </div>

            {isEditingName ? (
              <div className="flex space-x-2 items-center">
                <LabeledInput
                  labelColor="text-white"
                  placeholder="Enter organization name"
                  width={200}
                  label="Organization name"
                  value={name}
                  onChange={setName}
                />
                <button
                  onClick={changeOrganization}
                  className="py-1 px-2 h-[36px] text-white bg-black rounded-lg"
                >
                  Update
                </button>
                <button
                  onClick={() => {
                    setName(organization?.name || "");
                    setIsEditingName(false);
                  }}
                  className="py-1 px-2 text-white rounded-lg"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="flex space-x-1 justify-between items-center">
                <p className="text-white py-4 font-medium text-xl">
                  Welcome,
                  <br />
                  {name || organization?.name}
                </p>
                <p
                  onClick={() => {
                    setIsEditingName(true);
                  }}
                  className="underline text-white cursor-pointer"
                >
                  Edit
                </p>
              </div>
            )}

            {/* <span className="text-white text-xs">
            Balance amount (Coming soon!)
          </span> */}
            {/* <span className="text-white text-xl pt-1 blur-[2px]">$50.00</span> */}
          </div>

          {/* Applications Section */}
          <div className="py-4">
            <h2 className="text-lg font-medium mb-4">My Foster Applications</h2>
            <div className="overflow-y-auto min-h-[150px] max-h-[325px]">
              {applications?.length > 0 ? (
                applications.map((app) => (
                  <ApplicationCard
                    key={app.foster_id}
                    createdAt={app.createdAt}
                    foster_id={app.foster_id}
                    organization_id={app.organization_id || ""}
                  />
                ))
              ) : (
                <div>
                  <p className="bg-primary text-black rounded-lg px-4 py-2">
                    🤔 No applications so far. Try checking back later or keep a
                    lookout in your email.
                  </p>
                </div>
              )}
              <div
                onClick={() => {
                  navigate("/rescue/fosters");
                }}
                className="text-md text-center text-secondary-400 cursor-pointer underline pt-6"
              >
                Find new fosters
              </div>
            </div>
          </div>
        </div>

        {/* Additional Info Section */}
        <div className="max-w-sm sm:max-w-md md:max-w-sm lg:max-w-lg">
          <div className="p-4 rounded-lg bg-secondary-100 mb-6">
            <h3 className="text-xl font-semibold pb-2">Rescue More Animals</h3>
            <p className="text-lg">
              Animal rescues using our platform have seen a 30% increase in the
              number of animals they can rescue and rehome.
            </p>
          </div>

          <p className="text-lg font-semibold text-secondary-400">
            Together, we can give more animals a second chance at life.
          </p>

          <div
            onClick={() => {
              navigate("/contact");
            }}
            className="text-md cursor-pointer text-center text-secondary-400 mb-4 underline pt-6"
          >
            Reqest a demo
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
