import { useNavigate } from "react-router-dom";
import { Animal } from "../../types/types";

type Props = {
  pet: Animal;
  imageUrl?: string;
  name: string;
  breed: string;
  age: string;
  distance: number;
};

const PetCard = ({
  pet,
  imageUrl,
  name,
  breed,
  age,
  distance,
  ...rest
}: Props) => {
  const navigate = useNavigate();

  const cityState = pet.contact.address
    ? `${pet.contact.address.city}, ${pet.contact.address.state}`
    : "";

  const navigateToPet = (animal: Animal) => {
    navigate(`/pets/${animal.id}`, { state: { animal } });
  };

  return (
    <div
      {...rest}
      className="bg-white flex flex-col rounded-xl overflow-hidden shadow-lg border border-secondary-100 my-2 mx-1 w-[44%] sm:max-w-[225px] h-[300px]"
    >
      <button
        onClick={() => navigateToPet(pet)}
        className="w-full h-[63%] overflow-hidden"
      >
        <img
          className="w-full h-full object-cover"
          src={imageUrl ? imageUrl : require("../../assets/images/avatar.png")}
          alt="Pet"
        />
      </button>
      <div
        onClick={() => navigateToPet(pet)}
        className="p-4 flex flex-col space-y-1 cursor-pointer"
      >
        <p className="text-secondary-400 font-semibold text-lg truncate">
          {name}
        </p>
        <p className="text-sm text-gray-500">
          {distance ? `${distance.toFixed(1)} mi away` : "Nearby"}
        </p>
        <p className="text-sm text-gray-600 truncate">{breed}</p>
        <p className="text-sm text-gray-600">{cityState || ""}</p>
      </div>
    </div>
  );
};

export default PetCard;
