import { useState, useEffect } from 'react';
import { Address } from '../types/types';
import { auth } from '../services/firebaseService';
import { fetchAddress } from '../services/apiService';

const useAddress = (authId?: string): Address | null => {
  const [address, setAddress] = useState<Address | null>(null);
  const userId = auth.currentUser?.uid;
  useEffect(() => {
    const getAddress = async () => {
      if (!userId && !authId)  {
        return;
      }
      const res = await fetchAddress({ auth_user_id: authId ? authId :  userId || ''});
      if (res.address) {
        setAddress(res.address);
      }
    };
    getAddress();
  }, [authId, userId]);

  return address;
};

export default useAddress;