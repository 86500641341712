type Props = {
  label: string;
  value: string;
  placeholder: string;
  width?: number;
  height?: number;
  error?: string;
  success?: string;
  isSecure?: boolean;
  multiline?: boolean;
  showPassword?: boolean;
  disabled?: boolean;
  rightElement?: any;
  leftElement?: any;
  labelColor?: string;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  isFocused?: boolean;
};

export const LabeledInput = ({
  label,
  value,
  placeholder,
  onChange,
  error,
  isSecure = false,
  multiline = false,
  disabled = false,
  labelColor = "black",
  height,
  success,
  ...rest
}: Props) => {
  return (
    <div className="w-full max-w-xl mb-4">
      {/* Label */}
      <label className={`block text-sm font-semibold mb-2 ${labelColor}`}>
        {label}
      </label>

      {/* Input or Textarea depending on multiline prop */}
      {multiline ? (
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
          className={`w-full text-black text-lg px-4 py-2 border rounded-lg resize-none ${
            error ? "border-error" : "border-gray-300"
          } focus:border-blue-500 outline-none bg-white`}
          style={{ height: height ? `${height}px` : "auto" }} // Apply height if provided
          {...rest}
        />
      ) : (
        <input
          type={isSecure ? "password" : "text"}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
          className={`w-full text-black text-lg px-4 py-2 border rounded-lg ${
            error ? "border-error" : "border-gray-300"
          } focus:border-blue-500 outline-none bg-white`}
          style={{ height: height ? `${height}px` : "auto" }} // Apply height if provided
          {...rest}
        />
      )}

      {/* Error or Success Message */}
      {error && <p className="text-error text-sm mt-1">{error}</p>}
      {success && <p className="text-green-500 text-sm mt-1">{success}</p>}
    </div>
  );
};

export default LabeledInput;
