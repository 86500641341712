import { Foster } from "../types/types";
import { theme } from "./theme"

export const isMobile = (width: number) => {
    return width < theme.breakpoints.md;
}

export const isDesktop = (width: number) => {
    return width >= theme.breakpoints.md;
};

export const validateApplicationCompletion = (application: Foster, hasAddress: boolean) => {
    if (!hasAddress) {
      return 0;
    }

    if (!application.first_name || !application.last_name) {
        return 1;      
    }

    if (!application.existing_pets_description) {
        return 2;
    }

    return 3;
  };

  export const getQueryString = (queries: any) => {
    return Object.keys(queries)
      .filter(key => !!queries[key])
      .reduce((result: any, key: any) => {
        return [
          ...result,
          `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`,
        ];
      }, [])
      .join("&");
  };

  export function debounce<T extends (...args: any[]) => void>(fn: T, ms: number): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout>;
    
    return function (...args: Parameters<T>): void {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), ms);
    };
  }

  export const isRescueAccount = async () => {
    return !!window.localStorage.getItem('IS_RESCUE');
  };

  export const setIsRescueAccount = async (isRescue: boolean) => {
    if (isRescue) {
      window.localStorage.setItem('IS_RESCUE', 'true')
     } else { 
      window.localStorage.removeItem('IS_RESCUE')
    }
  };