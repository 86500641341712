import { useEffect, useState } from "react";
import { auth } from "../../services/firebaseService";
import {
  fetchAddress,
  fetchApplicationsForFoster,
  fetchFoster,
} from "../../services/apiService";
import Loading from "../../components/Loading";
import { logAnalyticsEvent } from "../../services/firebaseService";
import PersonalInformation from "../../components/ApplicationForm/PersonalInformation";
import { Address, Application, Foster } from "../../types/types";
import FosterPreferences from "../../components/ApplicationForm/FosterPreferences";
import HomeInformation from "../../components/ApplicationForm/HomeInformation";
import { useNavigate } from "react-router-dom";
import { validateApplicationCompletion } from "../../config/utils";
import FosterApplicationCard from "../../components/FosterApplicationCard";
import { onAuthStateChanged } from "firebase/auth";
import useFoster from "../../hooks/useFoster";
import { useAuth } from "../../hooks/AuthContext";

const UpdateAccount = () => {
  const navigate = useNavigate();
  const [sentApplications, setSentApplications] = useState<Application[]>([]);
  const { user } = useAuth();
  const userId = user?.uid;
  const foster = useFoster();

  const [application, setApplication] = useState<Foster | undefined>(undefined);
  const [fosterAddress, setFosterAddress] = useState<Address | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const [stepsCompleted, setStepsCompleted] = useState(0);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("IS_RESCUE");
        navigate("/login");
      }
    });
  }, [navigate]);

  const getApplicationData = async () => {
    if (!userId || isLoading) {
      return;
    }
    logAnalyticsEvent({ eventName: "profile_visited" });
    setIsLoading(true);
    const res = await fetchFoster({ auth_user_id: userId });
    const addressRes = await fetchAddress({ auth_user_id: userId });
    if (res.foster) {
      setApplication(res.foster);
      setFosterAddress(addressRes.address);

      const step = validateApplicationCompletion(
        res.foster,
        !!addressRes.address?.city
      );
      setStepsCompleted(step);

      // Check for submitting pet interruption
      const submittingPetId = window.localStorage.getItem("SUBMITTING_PET_ID");
      if (step === 3 && submittingPetId) {
        window.localStorage.removeItem("SUBMITTING_PET_ID");
        navigate(`/pets/${submittingPetId}`);
      }
    }
    setIsLoading(false);
  };

  const getFosterApplications = async () => {
    try {
      if (!userId) {
        return;
      }
      const res = await fetchApplicationsForFoster({ foster_id: foster?.id });
      if (res.applications) {
        setSentApplications(res.applications.reverse());
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getApplicationData();
    getFosterApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const SuccessDiv = () => {
    return stepsCompleted === 3 ? (
      <div className="w-full max-w-xl bg-white p-6 pb-8 mt-4 mb-2 rounded-lg text-center">
        <h1 className="text-3xl font-bold mb-4">
          {stepsCompleted === 3
            ? "Foster Profile Completed"
            : stepsCompleted >= 1
            ? "Finish"
            : "Let's Complete"}{" "}
          {stepsCompleted !== 3 ? "Your Foster Profile" : ""}
        </h1>
        <p className="text-md mb-6">
          You're all set! You can now start submitting your profile to rescues
          in your area.
        </p>
        <button
          className="w-full mt-4 bg-secondary-400 font-semibold text-white py-2 px-4 rounded-lg"
          onClick={() => navigate("/pets")}
        >
          Find Animals In Need
        </button>
        <button
          className="w-full mt-4 bg-black font-semibold text-white py-2 px-4 rounded-lg"
          onClick={() => navigate("/organizations")}
        >
          Connect with Rescues
        </button>

        {sentApplications?.length > 0 && (
          <>
            <h2 className="text-md font-medium mt-6 mb-2 text-left">
              Applications sent
            </h2>
            <div className="w-full mt-2 min-h-[120px]">
              {sentApplications.map((app) => (
                <FosterApplicationCard
                  key={app.createdAt}
                  was_viewed={app.was_viewed}
                  createdAt={app.createdAt}
                  updatedAt={app.updatedAt}
                  organization_id={app.organization_id || ""}
                />
              ))}
            </div>
          </>
        )}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="flex flex-col items-center bg-secondary-100 min-h-screen">
      <SuccessDiv />
      {isLoading ? (
        <Loading height={500} />
      ) : (
        <div className="mt-4 bg-white rounded-lg p-6 w-full max-w-xl">
          <div className="w-full">
            {stepsCompleted < 3 && (
              <h1 className="text-3xl font-bold mb-6">
                {stepsCompleted === 3
                  ? "Foster Profile Completed"
                  : stepsCompleted >= 1
                  ? "Finish"
                  : "Let's Complete"}{" "}
                {stepsCompleted !== 3 ? "Your Foster Profile" : ""}
              </h1>
            )}

            {stepsCompleted === 0 || stepsCompleted === 3 ? (
              <FosterPreferences
                isEditable={true}
                application={application}
                fosterAddress={fosterAddress}
                userId={userId || ""}
                onRefresh={getApplicationData}
              />
            ) : null}

            {stepsCompleted === 1 || stepsCompleted === 3 ? (
              <>
                <div className="w-full h-1 bg-secondary-200 my-6 rounded-full" />
                <PersonalInformation
                  isEditable={true}
                  application={application}
                  userId={userId || ""}
                  onRefresh={getApplicationData}
                />
              </>
            ) : undefined}

            {stepsCompleted === 2 || stepsCompleted === 3 ? (
              <>
                <div className="w-full h-1 bg-secondary-200 my-6 rounded-full" />
                <HomeInformation
                  isEditable={true}
                  application={application}
                  userId={userId || ""}
                  onRefresh={getApplicationData}
                />
              </>
            ) : undefined}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateAccount;
