import React from "react";
import { Pressable, StyleSheet, CheckBox, View } from "react-native-web";
import { theme } from "../../config/theme";
import Label from "../Label";

type Props = {
  onPress: (v: any) => void;
  isSelected: boolean;
  value: string;
  label?: string;
};

const LabeledCheckBox = ({ onPress, isSelected, label, value }: Props) => {
  return (
    <View>
      {label && (
        <Label weight="medium" sz="medium" styles={styles.text}>
          {label}
        </Label>
      )}

      <Pressable style={styles.container} onPress={onPress}>
        <CheckBox value={isSelected} />
        <Label styles={styles.valueText} sz="medium">
          {value}
        </Label>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    paddingBottom: 12,
    color: "black",
  },
  valueText: {
    color: theme.colors.text.body,
    paddingLeft: 8,
  },
  container: {
    paddingBottom: 16,
    maxWidth: 800,
    alignItems: "center",
    flexDirection: "row",
  },
});

export default LabeledCheckBox;
