import { useState } from "react";
import IconX from "../../assets/icons/IconX";

export default function ToolTip() {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      {/* Hyperlink Button */}
      <div className="flex gap-1 items-center">
        <p
          className="text-secondary-400 underline cursor-pointer"
          onClick={() => setShowTooltip(!showTooltip)}
        >
          Learn how it works
        </p>
      </div>

      {/* Tooltip */}
      {showTooltip && (
        <div className="absolute top-6 left-0 bg-white border border-gray-300 p-4 shadow-lg rounded-lg z-20 w-72 lg:w-96">
          <button
            className="flex items-center"
            onClick={() => setShowTooltip(false)}
          >
            <IconX />
          </button>
          <h1 className="text-black text-center font-bold pb-2">
            Create Campaigns
          </h1>
          <p className="text-gray-700 text-left text-sm">
            A campaign allows your organization to reach thousands of animal
            lovers in the Petfosterz network ready to foster and support you.
            <br /> <br />
            Whether you’re looking to place pets in temporary homes, manage
            overflow, or prepare for an upcoming rescue event, sending a
            campaign connects you directly with foster families eager to help.
            Simply share your needs, and let us amplify your reach!
          </p>
        </div>
      )}
    </div>
  );
}
