import {
  StyleSheet,
  View,
  Pressable,
  Image,
  Dimensions,
  ScrollView,
} from "react-native-web";
import Label from "../../components/Label";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { theme } from "../../config/theme";
import he from "he";
import { isMobile } from "../../config/utils";
import { Animal, PetFinderOrganization } from "../../types/types";
import Button from "../../components/Button";
import { createApplication } from "../../services/apiService";
import SuccessModal from "../../components/SuccessModal";
import PetCard from "../../components/PetCard";
import { fetchPetFinderAnimals } from "../../services/petfinderService";
import { FaArrowLeft } from "react-icons/fa";
import { logAnalyticsEvent } from "../../services/firebaseService";
import useFoster from "../../hooks/useFoster";
import LabeledInput from "../../components/LabeledInput";

const OrgDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const organization: PetFinderOrganization = state.organization;
  const foster = useFoster();

  const [petsList, setPetsList] = useState<Animal[]>([]);
  const [message, setMessage] = useState("");

  const [petImageUrl, setPetImageUrl] = useState(
    organization?.photos?.find((p) => p.full)?.full
  );
  const [didSendApplication, setDidSendApplication] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPetFinderAnimals({
        location: window.localStorage.getItem("LAT_LNG") || "",
        organization: organization.id,
        page: "1",
        limit: "12",
      });
      if (res?.animals) {
        setPetsList(res.animals);
      }
    };
    if (!petsList.length) {
      fetchData();
    }
  }, [organization.id, petsList]);

  const onSubmitApplication = async () => {
    if (foster) {
      setIsLoading(true);
      logAnalyticsEvent({
        eventName: "submit_application",
        data: { screen: "org" },
      });

      const res = await createApplication({
        foster_id: foster.id,
        organization_email: organization?.email || "achall@toplev.io",
        organization_name: organization?.name || "Your Rescue",
        petfinder_id: organization?.id,
        message: message,
      });
      setDidSendApplication(res.success);
      setIsLoading(false);
    } else {
      logAnalyticsEvent({
        eventName: "submit_application_attempt",
        data: { screen: "org" },
      });
      navigate("/create-account");
    }
  };

  return (
    <View
      style={{
        backgroundColor: theme.colors.primary,
        padding: 24,
        width: "100%",
      }}
    >
      <Pressable style={{ alignSelf: "start" }} onPress={() => navigate(-1)}>
        <FaArrowLeft size={28} />
      </Pressable>

      <View style={{ paddingTop: 24 }}>
        <Image
          style={styles({ isSelected: false }).petImage}
          source={
            petImageUrl
              ? { uri: petImageUrl }
              : require("../../assets/images/avatar.png")
          }
        />

        <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
          <View
            style={{
              width: "100%",
              paddingTop: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {organization.photos.map((photo, i) => {
              return (
                <Pressable key={i} onPress={() => setPetImageUrl(photo.full)}>
                  <Image
                    style={
                      styles({ isSelected: photo.full === petImageUrl })
                        .petImageSmall
                    }
                    source={{ uri: photo.full }}
                  />
                </Pressable>
              );
            })}
          </View>
        </ScrollView>

        <View style={styles({}).sectionContainer}>
          <Label sz="xlarge">{organization.name}</Label>
          <Label styles={{ paddingTop: 16 }} sz="medium">
            {organization.distance
              ? `${organization.distance.toFixed(1)} miles away`
              : ""}
          </Label>
          <Label styles={{ paddingTop: 16 }} sz="medium">
            {organization.address.address1
              ? `${organization.address.address1}\n`
              : ""}
            {organization.address.city}, {organization.address.state}{" "}
            {organization.address.postcode}
          </Label>

          {organization.phone && (
            <Label styles={{ paddingTop: 16 }} sz="medium">
              {organization.phone}
            </Label>
          )}
        </View>

        <View
          style={[
            styles({}).sectionContainer,
            {
              backgroundColor: theme.colors.secondary[400],
              alignItems: "center",
              paddingBottom: 24,
            },
          ]}
        >
          <Label styles={{ color: "white", padding: 16 }} sz="xlarge">
            Want to help {organization?.name}?
          </Label>

          <LabeledInput
            label=""
            value={message}
            onChange={setMessage}
            placeholder="Send a message (optional)"
            multiline
          />

          <Button
            backgroundColor="white"
            textColor={
              isLoading ? theme.colors.tertiary : theme.colors.secondary[400]
            }
            isDisabled={isLoading}
            onPress={onSubmitApplication}
            width={330}
          >
            SEND INTEREST
          </Button>
        </View>

        <View style={styles({}).sectionContainer}>
          <Label sz="medium">
            {organization.mission_statement?.split(".").length > 0
              ? `${he.decode(organization.mission_statement?.split(".")[0])}.`
              : ""}
            Contact {organization.name || "them"} for more information.
          </Label>
        </View>

        <Label sz="xlarge">{"Help Our Animals"}</Label>

        <View style={styles({}).gridContainer}>
          {petsList.map((pet, i) => {
            const formattedName = pet.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const petImageUrl =
              pet.photos.find((p) => p.medium)?.medium ||
              pet.primary_photo_cropped?.medium;
            return (
              <PetCard
                key={i}
                pet={pet}
                imageUrl={petImageUrl}
                name={formattedName}
                breed={pet.breeds.primary}
                age={pet.age}
                distance={pet.distance}
              />
            );
          })}
        </View>
      </View>
      <SuccessModal
        isVisible={didSendApplication}
        onDismiss={() => setDidSendApplication(false)}
      />
    </View>
  );
};

const styles = (props: any) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 10,
    },
    icon: {
      fontSize: 24,
      lineHeight: 24,
    },
    pressable: {
      paddingLeft: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    petImage: {
      borderRadius: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : 400,
      height: 365,
      resizeMode: "cover",
    },
    petImageSmall: {
      borderWidth: props.isSelected ? 3 : 0,
      borderColor: theme.colors.secondary[300],
      borderRadius: 12,
      marginRight: 12,
      width: 130,
      height: 100,
      resizeMode: "cover",
    },
    readMoreLink: {
      color: theme.colors.text.link,
    },
    sectionContainer: {
      marginTop: 16,
      marginBottom: 16,
      backgroundColor: "white",
      padding: 12,
      borderRadius: 8,
    },
    gridContainer: {
      paddingTop: 12,
      width: isMobile(Dimensions.get("window").width) ? "100%" : "60%",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
  });

export default OrgDetail;
