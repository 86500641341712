import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  imagePath: string; // Use string type for image paths in React
  name: string;
};

const BlogCard = ({ id, name, imagePath }: Props) => {
  const navigate = useNavigate();

  const navigateToBlog = () => {
    navigate(`/foster-tails/${id}`);
  };

  return (
    <div
      className="w-full relative group overflow-hidden bg-white border-2 border-info rounded-lg shadow-lg cursor-pointer max-w-xl"
      onClick={navigateToBlog}
    >
      <img
        src={imagePath}
        alt={name}
        className="w-full h-56 lg:h-64 object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-black opacity-25" />
      <div className="absolute bottom-4 left-4 text-white">
        <h3 className="text-xl font-semibold">{name}</h3>
      </div>
    </div>
  );
};

export default BlogCard;
