import React, { useState } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Link } from "react-router-dom";
import { FaBars, FaHome, FaUser, FaUserFriends } from "react-icons/fa";
import { theme } from "../../../config/theme";
import Label from "../../Label";

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <View
      style={[styles.sidebar, collapsed ? styles.collapsed : styles.expanded]}
    >
      <TouchableOpacity onPress={toggleSidebar} style={styles.toggleButton}>
        <FaBars size={20} color={theme.colors.secondary[300]} />
      </TouchableOpacity>
      <View style={styles.nav}>
        <Link to="/rescue" style={styles.navItem}>
          <FaHome size={20} color={theme.colors.secondary[300]} />
          {!collapsed && (
            <Label sz="medium" styles={styles.navText}>
              Home
            </Label>
          )}
        </Link>
        <Link to="/rescue/fosters" style={styles.navItem}>
          <FaUserFriends size={20} color={theme.colors.secondary[300]} />
          {!collapsed && (
            <Label sz="medium" styles={styles.navText}>
              Fosters
            </Label>
          )}
        </Link>
        {/* <Link to="/rescue/animals" style={styles.navItem}>
          <FaPaw size={20} color={theme.colors.secondary[300]} />
          {!collapsed && (
            <Label sz="medium" styles={styles.navText}>
              Animals
            </Label>
          )}
        </Link> */}
        <Link to="/rescue/account" style={styles.navItem}>
          <FaUser size={20} color={theme.colors.secondary[300]} />
          {!collapsed && (
            <Label sz="medium" styles={styles.navText}>
              Account
            </Label>
          )}
        </Link>
        {/* <Link to="/contact" style={styles.navItem}>
          <FaComment color={theme.colors.secondary[300]} />
          {!collapsed && (
            <Label sz="medium" styles={styles.navText}>
              Support
            </Label>
          )}
        </Link> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    backgroundColor: theme.colors.secondary[100],
    color: "#fff",
    overflow: "hidden",
  },
  collapsed: {
    width: 60,
  },
  expanded: {
    width: 180,
  },
  toggleButton: {
    paddingTop: 24,
    paddingLeft: 20,
  },
  nav: {
    padding: 20,
  },
  navItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 20,
    textDecorationLine: "none",
  },
  navText: {
    marginLeft: 12,
    color: theme.colors.secondary[300],
  },
});

export default Sidebar;
