import ItemPicker from "../ItemPicker";
import IconSearch from "../../assets/icons/IconSearch";
import { theme } from "../../config/theme";

type Props = {
  search: string;
  setSearch: (v: string) => void;
  petType?: string;
  setPetType?: (v: string) => void;
  onPressSearch: () => void;
  placeholder?: string;
};

const FilterSearchInput = ({
  search,
  setSearch,
  petType,
  setPetType,
  onPressSearch,
  placeholder,
}: Props) => {
  return (
    <div className="bg-white px-3 py-2 flex items-center rounded-lg justify-between w-full max-w-[800px] min-w-xs space-x-3">
      {/* Pet Type Picker */}
      {setPetType && (
        <>
          <ItemPicker
            selectedValue={petType || ""}
            items={["All pets", "Dog", "Cat"]}
            onPressItem={(v, i) => setPetType(v)}
          />
          <div className="h-[50px] w-[1px] bg-gray-300 mx-3"></div>
        </>
      )}

      {/* Search Input */}
      <input
        type="text"
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="w-full h-10 rounded-md px-2 placeholder-gray-400"
        style={{ backgroundColor: "white", color: theme.colors.tertiary }}
      />

      {/* Search Button */}
      <button
        onClick={onPressSearch}
        className="bg-secondary-400 rounded-md w-12 h-10 flex items-center justify-center text-white"
      >
        <IconSearch />
      </button>
    </div>
  );
};

export default FilterSearchInput;
