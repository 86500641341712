import { useState } from "react";
import { Foster } from "../../../types/types";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import { updateFoster } from "../../../services/apiService";
import LabeledInput from "../../LabeledInput";
import LabeledSwitch from "../../LabeledSwitch"; // Keeping LabeledSwitch if you want it for toggling

type Props = {
  isEditable: boolean;
  application?: Foster;
  userId: string;
  onRefresh?: () => void;
};

const HomeInformation = ({ isEditable, application, onRefresh }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(
    isEditable && !application?.existing_pets_description
  );

  const [residenceOwned, setResidenceOwned] = useState(
    application?.residence_owned || false
  );
  const [existingPetsDescription, setExistingPetsDescription] = useState(
    application?.existing_pets_description || ""
  );
  const [roommatesDescription, setRoommatesDescription] = useState(
    application?.roommates_description || ""
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return;

    setError("");
    setIsLoading(true);
    logAnalyticsEvent({ eventName: "home_information_updated" });

    if (application?.id) {
      const res = await updateFoster({
        id: application?.id,
        residence_owned: residenceOwned,
        roommates_description: roommatesDescription,
        existing_pets_description: existingPetsDescription,
      });

      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      } else {
        setError("Failed to save. Please try again later.");
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="w-full">
      {/* Editable Section */}
      <div className="flex items-center">
        <h2 className="text-xl font-semibold mr-4">Home Information</h2>
        {isEditable && application?.existing_pets_description && isEditing ? (
          <p
            className="text-secondary-400 underline cursor-pointer"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </p>
        ) : (
          isEditable && (
            <p
              className="text-secondary-400 underline cursor-pointer"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </p>
          )
        )}
      </div>

      {isEditing && !application?.existing_pets_description && (
        <p className="text-md text-gray-700 mt-1">
          Help us understand your living situation.
        </p>
      )}

      <form className="mt-4" onSubmit={onSubmit}>
        {/* Existing Pets Description */}
        {isEditing ? (
          <LabeledInput
            label="Any Existing pets?"
            value={existingPetsDescription}
            onChange={setExistingPetsDescription}
            placeholder="List any pets or put 'None'."
          />
        ) : (
          <div className="mb-4">
            <p className="text-sm font-semibold">Existing pets</p>
            <p className="text-md">
              {application?.existing_pets_description || "None"}
            </p>
          </div>
        )}

        {/* Roommates Description */}
        {isEditing ? (
          <LabeledInput
            label="Roommates description (optional)"
            value={roommatesDescription}
            onChange={setRoommatesDescription}
            placeholder="Describe who you live with"
          />
        ) : (
          <div className="mb-4">
            <p className="text-sm font-semibold">Roommates description</p>
            <p className="text-md">
              {application?.roommates_description || "None"}
            </p>
          </div>
        )}

        {/* Residence Owned */}
        {isEditing ? (
          <LabeledSwitch
            onPress={(v) => setResidenceOwned(v.target.checked)}
            isSelected={residenceOwned}
            label="Own or rent"
            value="I own my residence"
          />
        ) : (
          <div className="mb-4">
            <p className="text-sm font-semibold">Residence owned</p>
            <p className="text-md">
              {application?.residence_owned ? "Yes" : "No"}
            </p>
          </div>
        )}

        {/* Error Handling */}
        {error && <p className="text-error text-sm mt-4">{error}</p>}

        {/* Submit Button */}
        {isEditing && (
          <button
            type="submit"
            disabled={isLoading || !existingPetsDescription}
            className={`w-full mt-6 bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg ${
              isLoading || !existingPetsDescription
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-800"
            }`}
          >
            {application?.existing_pets_description ? "Update" : "Finish"}
          </button>
        )}
      </form>
    </div>
  );
};

export default HomeInformation;
