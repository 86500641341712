import { useState, useEffect } from 'react';
import { Foster } from '../types/types';
import { fetchFoster } from '../services/apiService';
import { useAuth } from './AuthContext';

const useFoster = (): Foster | null => {
    const {user} = useAuth();
    const [foster, setFoster] = useState<Foster | null>(null);
    useEffect(() => {
        const getFoster = async () => {
          if (!user) {
            // nothing to fetch
            return;
          }
          if (user) {
            const authUserId = user.uid;
            const res = await fetchFoster({auth_user_id: authUserId});
            if (res.foster) {
              setFoster(res.foster);
            }
          }
        
        };
        
        getFoster();
        
      }, [user]);

    return foster;
};

export default useFoster;