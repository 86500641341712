import React from "react";
import { IconGoogle } from "../../assets/icons/IconGoogle";

type Props = {
  onPress: () => void;
  width?: number;
  borderWidth?: number;
};

const GoogleLoginButton = ({
  onPress,
  width = 250,
  borderWidth = 0,
}: Props) => {
  return (
    <button
      onClick={onPress}
      className={`flex items-center justify-center bg-white border rounded-lg py-2 px-4 mt-4 w-full max-w-lg`}
    >
      <IconGoogle />
      <span className="text-center font-semibold text-base pl-2">
        Continue with Google
      </span>
    </button>
  );
};

export default GoogleLoginButton;
