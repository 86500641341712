import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Animal } from "../../types/types";
import PetCard from "../../components/PetCard";
import { fetchPetFinderAnimals } from "../../services/petfinderService";
import IconMedical from "../../assets/icons/IconMedical";
import InfoCard from "../../components/InfoCard";
import IconHome from "../../assets/icons/IconHome";
import IconDogWalk from "../../assets/icons/IconDogWalk";
import Loading from "../../components/Loading";
import { logAnalyticsEvent } from "../../services/firebaseService";
import Signup from "../Signup";
import { Reveal } from "../../components/Reveal";
import BlogCard from "../../components/BlogCard";
import UseCurrentLocation from "../../components/UseCurrentLocation";

const Home = () => {
  const navigate = useNavigate();
  const [featuredPets, setFeaturedPets] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchFeaturedPets = async (location: string) => {
    logAnalyticsEvent({ eventName: "home_screen_fetch", data: { location } });
    setIsLoading(true);
    const res = await fetchPetFinderAnimals({ location, limit: "4" });
    if (res?.animals) {
      setFeaturedPets(
        res.animals?.sort((a) => (a.photos?.length > 0 ? 1 : -1))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFeaturedPets(window.localStorage.getItem("LAT_LNG") || "33301");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasLocationSet, setHasLocationSet] = useState(
    !!window.localStorage.getItem("LAT_LNG")
  );

  const onPressSignup = () => {
    logAnalyticsEvent({ eventName: "home_screen_signup" });
    navigate("/create-account");
  };

  const onPressViewMore = () => {
    logAnalyticsEvent({ eventName: "home_screen_view_more" });
    navigate("/pets");
  };

  return (
    <div className="overflow-hidden">
      <div className="relative h-screen h-[78vh] w-full overflow-hidden">
        {/* Hero Background Image */}
        <div className="absolute inset-0 z-0 w-full">
          <img
            src={require("../../assets/images/hero-hugging-dog.jpeg")} // Replace with your image path
            alt="Hero"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Hero Content */}
        <div className="relative z-9 flex flex-col h-full bg-black bg-opacity-50 p-8 md:p-16 lg:p-24 xl:p-44 text-white animate-fade-in items-center">
          <div className="w-full animate-drop-in mt-36 md:mt-64 xl:mt-10">
            <h1 className="text-5xl md:text-6xl font-semibold mb-6">
              Fostering Hope,
              <br />
              One Paw at a Time.
            </h1>
            <p className="text-xl md:text-xl mb-8 lg:max-w-screen-md">
              Earn money while changing lives and saving animals in need.
            </p>

            <button
              onClick={onPressSignup}
              className="bg-secondary-400 text-white font-semibold text-lg py-2 px-6 rounded-lg align-center"
            >
              BECOME A FOSTER
            </button>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className="bg-secondary-200 h-4 w-full" />

      {/* Featured Pets Section */}
      <div className="w-full flex flex-col items-center mt-4 lg:mt-6">
        <Reveal width="100%">
          <div className="px-2 py-6 flex flex-col items-center">
            <div className="text-4xl font-semibold text-center pb-4">
              Find a Foster Pet
            </div>

            {!hasLocationSet ? (
              <UseCurrentLocation
                onLocationFound={(l) => {
                  setHasLocationSet(true);
                  fetchFeaturedPets(l);
                }}
              />
            ) : null}

            {isLoading || !featuredPets?.length ? (
              <Loading height={200} />
            ) : (
              <>
                <div className="flex flex-wrap pt-4 justify-center items-center w-full">
                  {featuredPets
                    .sort((a) => (a.photos.length ? 1 : -1))
                    .map((pet, i) => {
                      const formattedName = pet.name
                        .toLowerCase()
                        .split(" ")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ");
                      return (
                        <PetCard
                          key={i}
                          pet={pet}
                          name={formattedName}
                          imageUrl={pet.photos.find((p) => p.medium)?.medium}
                          breed={pet.breeds.primary}
                          age={pet.age}
                          distance={pet.distance}
                        />
                      );
                    })}
                </div>
              </>
            )}

            {featuredPets?.length ? (
              <div
                onClick={onPressViewMore}
                className="text-lg underline cursor-pointer pt-4 text-secondary-400"
              >
                View more
              </div>
            ) : null}
          </div>
        </Reveal>

        {/* Info Cards */}
        <Reveal width="100%">
          <div
            className={`px-4 py-12 mt-8 flex flex-col items-center bg-primary`}
          >
            <div className="text-4xl font-semibold text-center pb-8 lg:pb-12 max-w-xl">
              Help Us Ease The Overwhelming Demand on Animal Rescues
            </div>

            <div
              className={`flex w-full flex-col lg:flex-row space-x-0 lg:space-x-6 space-y-6 lg:space-y-0 items-center justify-center`}
            >
              <InfoCard
                icon={<IconMedical />}
                title="Transportation"
                message="Get paid to transport rescue animals to vet appointments, foster homes, and adoption events, ensuring their safety along the way."
              />

              <InfoCard
                backgroundColor={"bg-secondary-400"}
                textColor="text-white"
                icon={<IconHome />}
                title="Pet Fostering"
                message="Offer a temporary home to pets in need and earn extra income by pet sitting rescue animals with all expenses covered."
              />

              <InfoCard
                icon={<IconDogWalk />}
                title="Volunteer Opportunities"
                message="Receive real-time alerts for volunteer needs at shelters, from walking dogs to helping at events, and more."
              />
            </div>

            {/* Sign Up Section */}
            <div className="mt-8 w-full flex justify-center">
              <button
                onClick={onPressSignup}
                className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg"
              >
                GET INVOLVED
              </button>
            </div>
          </div>
        </Reveal>
      </div>

      <div className="px-4 flex flex-col items-center py-12">
        <div className="text-4xl font-semibold pb-6 text-center">
          Our Mission
        </div>

        <div className="max-w-[1000px] text-center space-y-6 mb-6">
          <p className="text-lg">
            At PetFosterz, we are on a mission to empower animal rescues by
            connecting them with the world's largest online community of foster
            homes, dedicated volunteers, and experienced workers, all committed
            to helping animals in need.
            <br /> <br />
            We believe that by uniting people with a shared love for animals, we
            can make a pawsitive impact on the lives of countless pets, reduce
            the overcrowding in shelters, and foster a community where every pet
            has the opportunity to thrive and flourish.
          </p>
        </div>
      </div>

      {/* Blog Section */}
      <div className="px-4 flex flex-col items-center">
        <div className="flex flex-row space-x-4 overflow-hidden">
          <img
            className="w-full h-24 object-contain"
            alt="paws"
            src={require("../../assets/images/paws.png")}
          />
          <img
            className="w-full h-24 object-contain"
            alt="paws"
            src={require("../../assets/images/paws.png")}
          />
          <img
            className="w-full h-24 object-contain"
            alt="paws"
            src={require("../../assets/images/paws.png")}
          />
        </div>

        <div className="text-4xl font-semibold pb-8 lg:pb-12 text-center pt-16">
          Foster Resources
        </div>

        {/* Wrapper div for max-w-xl and grid layout */}
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-2 gap-4 w-full max-w-[1200px]">
          <BlogCard
            id="how-does-pet-fostering-work"
            name="How Pet Fostering Works: What To Know"
            imagePath={require("../../assets/images/paw-high-five.jpeg")}
          />
          <BlogCard
            id="why-become-a-pet-foster"
            name="Why Pet Fostering Matters: Your Role in Changing Lives"
            imagePath={require("../../assets/images/hero-caged-dog.jpeg")}
          />

          <BlogCard
            id="all-about-cat-fostering"
            name="All About Cat Fostering"
            imagePath={require("../../assets/images/foster-cat.jpeg")}
          />
          <BlogCard
            id="foster-journey"
            name="A Foster Journey: How One Family Gave a Dog a Second Chance"
            imagePath={require("../../assets/images/dog-high-five.jpeg")}
          />
        </div>

        <div
          onClick={() => navigate("/foster-tails")}
          className="text-lg text-center underline cursor-pointer pt-4 text-secondary-400"
        >
          Read more
        </div>
      </div>

      {/* Join Now Section */}
      <div className="w-full flex justify-center pt-8">
        <Reveal width="100%">
          <Signup />
        </Reveal>
      </div>
    </div>
  );
};

export default Home;
