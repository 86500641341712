import { useState, useEffect } from 'react';
import { Organization } from '../types/types';
import { fetchOrganization, fetchOrganizationByEmail, fetchOrganizationById } from '../services/apiService';
import { useAuth } from './AuthContext';

type Props = {
  organizationId?: string;
  email?: string;
};
const useOrganization = ({organizationId, email}: Props): Organization | null => {
  const {user} = useAuth();
  const [organization, setOrganization] = useState<Organization | null>(null);
  
  useEffect(() => {
    const getOrganization = async () => {
      if (!user && !organizationId && !email) {
        // nothing to fetch
        return;
      }
      if (email) {
        const res = await fetchOrganizationByEmail({email});
        if (res.organization) {
          setOrganization(res.organization);
        }
      }
      else if (organizationId) {
        const res = await fetchOrganizationById({organization_id: organizationId});
        if (res.organization) {
          setOrganization(res.organization);
        }
      } else if (user) {
        const authUserId = user.uid;
        const res = await fetchOrganization({auth_user_id: authUserId  });
        if (res.organization) {
          setOrganization(res.organization);
        }
      }
    
    };
    
    getOrganization();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organizationId]);

  return organization;
};

export default useOrganization;