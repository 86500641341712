import React, { useEffect, useState } from "react";
import { StyleSheet, View, Dimensions } from "react-native-web";
import { auth, logAnalyticsEvent } from "../../../services/firebaseService";
import Label from "../../../components/Label";
import { fetchOrganization } from "../../../services/apiService";
import { Organization } from "../../../types/types";
import { theme } from "../../../config/theme";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined
  );

  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_account_visited" });
    const getOrganization = async () => {
      if (!auth.currentUser) {
        navigate("/");
        return;
      }
      const res = await fetchOrganization({
        auth_user_id: auth.currentUser?.uid,
      });
      if (res.organization) {
        setOrganization(res.organization);
      } else if (res.error) {
        setError(res.error);
      }
    };
    getOrganization();
  }, [organization, navigate]);

  return (
    <View style={styles.container}>
      <Label styles={styles.headerText} sz="xlarge" weight="bold">
        Your account
      </Label>

      <View style={{ height: 600 }}>
        {error ? (
          <Label sz="medium" styles={styles.error}>
            {error}
          </Label>
        ) : null}
        <Label sz="xsmall">{"Name:"}</Label>
        <Label sz="medium" styles={styles.text}>
          {organization?.name}
        </Label>
        <Label sz="xsmall">{"Email:"}</Label>
        <Label sz="medium" styles={styles.text}>
          {organization?.email}
        </Label>
        <Label sz="xsmall">{"Contact us:"}</Label>
        <Label
          onPress={() => navigate("/contact")}
          sz="medium"
          styles={[styles.text, { textDecorationLine: "underline" }]}
        >
          support@petfosterz.com
        </Label>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    height: Dimensions.get("window").height,
    width: Dimensions.get("window").width,
  },
  text: {
    width: "100%",
    paddingBottom: 16,
    color: "black",
  },
  error: {
    width: "100%",
    paddingBottom: 16,
    color: theme.colors.error,
  },
  headerText: {
    color: theme.colors.secondary[400],
    width: "100%",
    maxWidth: 800,
    paddingBottom: 16,
  },
});

export default Account;
