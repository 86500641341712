import { useState } from "react";
import { makeIntoPhoneNumber } from "../../../config/formatter";
import { updateFoster } from "../../../services/apiService";
import { auth, logAnalyticsEvent } from "../../../services/firebaseService";
import LabeledInput from "../../LabeledInput";
import LabeledSwitch from "../../LabeledSwitch";
import { Foster } from "../../../types/types";

type Props = {
  isEditable: boolean;
  application?: Foster;
  userId: string;
  onRefresh?: () => void;
};

const PersonalInformation = ({
  isEditable,
  application,
  userId,
  onRefresh,
}: Props) => {
  const [isEditing, setIsEditing] = useState(
    !application?.first_name &&
      !application?.last_name &&
      !application?.phone &&
      isEditable
  ); //default to editing state if no application exists
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [email, setEmail] = useState(
    application?.email ||
      auth.currentUser?.email ||
      window.localStorage.getItem("email") ||
      ""
  );
  const [firstName, setFirstName] = useState(application?.first_name || "");
  const [lastName, setLastName] = useState(application?.last_name || "");
  const [phone, setPhone] = useState(application?.phone || "");
  const [isAdult, setIsAdult] = useState(application?.is_adult || false);

  const isGuest = application?.auth_user_id !== userId;

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }
    setError("");
    setIsLoading(true);

    if (application?.id) {
      logAnalyticsEvent({ eventName: "contact_information_updated" });
      const res = await updateFoster({
        id: application?.id,
        email,
        first_name: firstName,
        last_name: lastName,
        phone,
        is_adult: isAdult,
      });

      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="w-full">
      <div className="flex items-center">
        <h2 className="text-xl font-semibold mr-4">Contact information</h2>
        {isEditable && application?.first_name && isEditing ? (
          <p
            className="text-secondary-400 underline cursor-pointer"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </p>
        ) : (
          isEditable && (
            <p
              className="text-secondary-400 underline cursor-pointer"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </p>
          )
        )}
      </div>
      {isEditing && !application?.first_name && (
        <p className="text-md text-gray-700 mb-4">Step 2 of 3.</p>
      )}

      {isEditing ? (
        <div className="pt-4">
          {application?.first_name ? (
            <LabeledInput
              label="Email address"
              value={email}
              onChange={setEmail}
              placeholder=""
            />
          ) : null}
        </div>
      ) : (
        <div className="my-4">
          <p className={`text-sm font-semibold`}>Email</p>
          <p
            className={`text-md cursor-pointer ${isGuest ? "underline" : ""}`}
            onClick={() => window.open("mailto:" + email)}
          >
            {email}
          </p>
        </div>
      )}

      {isEditing ? (
        <LabeledInput
          label="First name"
          value={firstName}
          onChange={setFirstName}
          placeholder=""
        />
      ) : (
        <div className="mb-4">
          <p className="text-sm font-semibold">Full name</p>
          <p className="text-md">
            {firstName} {lastName}
          </p>
        </div>
      )}

      {isEditing ? (
        <LabeledInput
          label="Last name"
          value={lastName}
          onChange={setLastName}
          placeholder=""
        />
      ) : null}

      {isEditing ? (
        <LabeledInput
          label="Phone (optional)"
          value={phone}
          onChange={(v) => setPhone(makeIntoPhoneNumber(v))}
          placeholder=""
        />
      ) : (
        <div className="mb-4">
          <p className="text-sm font-semibold">Phone number</p>
          <p
            className={`text-md ${
              isGuest && phone ? "underline" : ""
            } cursor-pointer`}
            onClick={() => window.open("tel:" + phone)}
          >
            {phone || "None provided"}
          </p>
        </div>
      )}

      {isEditing ? (
        <LabeledSwitch
          onPress={(v) => setIsAdult(v.target.checked || false)}
          isSelected={isAdult}
          label="Age"
          value="I'm 18 or older (optional)"
        />
      ) : null}

      {error && <p className="text-red-500 text-sm mt-4">{error}</p>}

      {isEditing && (
        <button
          className={`w-full mt-6 bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg ${
            !firstName || !lastName || isLoading
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-gray-800"
          }`}
          onClick={onSubmit}
        >
          {application?.first_name ? "Update" : "Continue"}
        </button>
      )}
    </div>
  );
};

export default PersonalInformation;
