import { View, Pressable } from "react-native-web";
import { IconSuccess } from "../../assets/icons/IconSuccess";
import Label from "../Label";
import IconX from "../../assets/icons/IconX";
import Button from "../Button";
import { theme } from "../../config/theme";
import Modal from "react-modal";

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
};
const SuccessModal = ({ isVisible, onDismiss }: Props) => {
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "#00000065",
        },
        content: {
          alignItems: "center",
          justifySelf: "center",
          maxWidth: 800,
          alignSelf: "center",
          color: theme.colors.secondary[100],
          borderRadius: "12px",
        },
      }}
      isOpen={isVisible}
      onRequestClose={onDismiss}
    >
      <View
        style={{
          padding: 20,
        }}
      >
        <Pressable onPress={onDismiss}>
          <IconX width={36} height={36} />
        </Pressable>

        <View
          style={{
            paddingTop: 16,
            alignItems: "center",
          }}
        >
          <IconSuccess color={theme.colors.secondary[200]} />
          <Label
            styles={{ paddingTop: 28, paddingBottom: 8, textAlign: "center" }}
            sz="large"
            weight="medium"
          >
            Request Sent!
          </Label>
          <Label
            styles={{
              paddingBottom: 28,
              textAlign: "center",
            }}
            sz="medium"
          >
            You can track the status of your application in your dashboard
            anytime.
          </Label>
          <Button
            backgroundColor="white"
            borderColor={theme.colors.secondary[300]}
            textColor={theme.colors.secondary[300]}
            onPress={onDismiss}
            width={140}
          >
            Dismiss
          </Button>
        </View>
      </View>
    </Modal>
  );
};
export default SuccessModal;
