import GooglePlacesAutocomplete, {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Address } from "../../types/types";
import UseCurrentLocation from "../UseCurrentLocation";

type Props = {
  label: string;
  value: any;
  placeholder: string;
  error?: string;
  success?: string;
  isSecure?: boolean;
  showPassword?: boolean;
  disabled?: boolean;
  rightElement?: any;
  leftElement?: any;
  onChange: (place: any) => void;
  onFocus?: () => void;
  isFocused?: boolean;
};

export const LabeledAddressInput = ({
  label,
  value,
  placeholder,
  onChange,
  error,
  success,
  isSecure = false,
  showPassword = false,
  disabled,
  leftElement,
  onFocus,
  isFocused,
  ...rest
}: Props) => {
  const mapToAddress = (placeDetails: google.maps.GeocoderResult): Address => {
    const getComponent = (type: string) => {
      const component = placeDetails.address_components.find((comp) =>
        comp.types.includes(type)
      );
      return component ? component.long_name : "";
    };

    // Extract latitude and longitude from placeDetails.geometry
    const latitude = placeDetails.geometry?.location?.lat() || 0.0;
    const longitude = placeDetails.geometry?.location?.lng() || 0.0;

    return {
      street_1: `${getComponent("street_number")} ${getComponent("route")}`,
      city: getComponent("locality"),
      state: getComponent("administrative_area_level_1"),
      postal_code: getComponent("postal_code"),
      country: getComponent("country"),
      latitude,
      longitude,
    } as Address;
  };
  const handleSelect = async (place: any) => {
    if (!place?.value?.place_id) {
      return;
    }
    const results = await geocodeByPlaceId(place.value.place_id);
    if (results.length > 0) {
      const mappedAddress = mapToAddress(results[0]);
      onChange(mappedAddress);
    }
  };

  const onLocationFound = async (lat: number, lng: number) => {
    const results = await geocodeByLatLng({ lat, lng });
    if (results.length > 0) {
      const mappedAddress = mapToAddress(results[0]);
      onChange(mappedAddress);
    }
  };
  return (
    <div className="relative z-10" {...rest}>
      <div className="flex items-center space-x-2">
        <p className="text-sm font-medium text-gray-700">{label}</p>
        <UseCurrentLocation
          onLocationFound={(latLng) => {
            if (latLng?.split(",") && latLng.split(",").length > 1) {
              const lat = latLng.split(",")[0];
              const lng = latLng.split(",")[1];
              onLocationFound(Number(lat), Number(lng));
            }
          }}
        />
      </div>

      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_KEY}
        selectProps={{
          value: value?.street_1,
          placeholder: "Enter a city, state, or address",
          onChange: handleSelect,
          styles: {
            container: (provided) => ({
              ...provided,
              marginBottom: 16,
              zIndex: 10,
            }),
            input: (provided) => ({
              ...provided,
            }),
            option: (provided) => ({
              ...provided,
            }),
          },
        }}
      />
    </div>
  );
};

export default LabeledAddressInput;
