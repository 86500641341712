import { useState, useCallback, useRef } from 'react';
import { PetFinderOrganization } from '../types/types';
import { fetchPetfinderOrganizations } from '../services/petfinderService';

const useFetchPetFinderOrganizations = (location: string, search: string) => {
  const [organizationsList, setOrganizationsList] = useState<PetFinderOrganization[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);

  // Keep track of previous search to avoid unnecessary API calls
  const previousSearchRef = useRef<string>("");

  const fetchData = useCallback(async () => {
    // Ensure fetch is only blocked if this is not the initial request
    if (previousSearchRef.current === search && organizationsList.length > 0 && page === 1) {
      // Prevent re-fetching if search and page are the same and data already exists
      return;
    }

    setIsLoading(true);
    setError("");
    previousSearchRef.current = search;

    try {
      const res = await fetchPetfinderOrganizations({
        location,
        query: search,
        page: page.toString(),
      });

      if (res?.organizations && res?.organizations.length > 0) {
        setOrganizationsList((prev) => (page > 1 ? [...prev, ...res.organizations] : res.organizations));
      } else {
        setOrganizationsList(page > 1 ? prev => prev : []);
        setError("No results were found in your area. Try checking back later.");
      }
    } catch (err) {
      setError("Failed to fetch organizations. Please try again.");
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, search, page]);

  const loadNextPage = useCallback(() => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      fetchData();
      return newPage;
    });
  }, [fetchData]);

  return { organizationsList, isLoading, error, fetchData, loadNextPage };
};

export default useFetchPetFinderOrganizations;
