import { useState } from "react";
import { Address, Foster } from "../../../types/types";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import { updateAddress, updateFoster } from "../../../services/apiService";
import LabeledInput from "../../LabeledInput";
import LabeledSwitch from "../../LabeledSwitch";
import LabeledCheckBox from "../../LabeledCheckBox";
import LabeledAddressInput from "../../LabeledAddressInput";

type Props = {
  isEditable: boolean;
  application?: Foster;
  userId: string;
  fosterAddress?: Address;
  onRefresh?: () => void;
};

const FosterPreferences = ({
  isEditable,
  application,
  userId,
  fosterAddress,
  onRefresh,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [address, setAddress] = useState<Address | undefined>(fosterAddress);
  const [breedPreference, setBreedPreference] = useState(
    application?.breed_preference || ""
  );
  const [isEditing, setIsEditing] = useState(
    isEditable && !fosterAddress?.city
  ); // always require at least a city
  const [hasFosterExperience, setHasFosterExperience] = useState(
    application?.has_foster_experience || false
  );
  const [dogAgePreferenceLow, setDogAgePreferenceLow] = useState(0);
  const [dogAgePreferenceHigh, setDogAgePreferenceHigh] = useState(2);
  const [preferredFosterDurationLow, setPreferredFosterDurationLow] =
    useState(0);
  const [preferredFosterDurationHigh, setPreferredFosterDurationHigh] =
    useState(1);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return;

    setError("");
    setIsLoading(true);

    logAnalyticsEvent({ eventName: "foster_preferences_updated" });

    if (application?.id) {
      const res = await updateFoster({
        id: application.id,
        has_foster_experience: hasFosterExperience,
        breed_preference: breedPreference,
        animal_age_preference_low: dogAgePreferenceLow,
        animal_age_preference_high: dogAgePreferenceHigh,
        preferred_foster_duration_low: preferredFosterDurationLow,
        preferred_foster_duration_high: preferredFosterDurationHigh,
        address: { ...address, auth_user_id: userId },
      });

      if (address) {
        await updateAddress({ ...address, auth_user_id: userId });
      }

      if (res.success) {
        setIsEditing(false);
        onRefresh && onRefresh();
      } else {
        setError("Failed to save. Please try again later.");
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="w-full">
      {/* Editing Toggle */}
      <div className="flex items-center pb-4">
        <h2 className="text-xl font-semibold mr-4">Foster Information</h2>
        {isEditable && isEditing && !address?.city ? (
          <p className="text-lg text-gray-700 pr-4 mb-2">
            This information will help us connect you to the right Animal
            Rescues 🐶🏠
          </p>
        ) : null}

        {isEditable && address?.city && isEditing ? (
          <p
            className="text-secondary-400 underline cursor-pointer"
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </p>
        ) : (
          isEditable && (
            <p
              className="text-secondary-400 underline cursor-pointer"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </p>
          )
        )}
      </div>

      <form className="space-y-4" onSubmit={onSubmit}>
        {isEditing ? (
          <>
            <LabeledAddressInput
              label="Location"
              value={{
                label: address?.street_1?.trim()
                  ? `${address?.street_1}, ${address.city}, ${address.state}`
                  : address?.city
                  ? `${address?.city}, ${address?.state}`
                  : "",
              }}
              onChange={setAddress}
              placeholder=""
            />
          </>
        ) : (
          <div className="w-full">
            <p className="text-sm font-semibold">Location</p>
            <p className="text-md">
              {address?.street_1?.trim()
                ? `${address?.street_1}, ${address.city}, ${address.state}`
                : address?.city
                ? `${address?.city}, ${address?.state}`
                : "Available upon request"}
            </p>
          </div>
        )}

        {/* Foster Experience */}
        {isEditing ? (
          <LabeledSwitch
            onPress={(v) => setHasFosterExperience(v.target.checked)}
            isSelected={hasFosterExperience}
            label="Foster experience"
            value="I have prior experience fostering a dog or cat"
          />
        ) : (
          <div>
            <p className="text-sm font-semibold text-gray-600">
              Foster experience
            </p>
            <p className="text-md">
              {application?.has_foster_experience ? "Yes" : "No"}
            </p>
          </div>
        )}

        {/* Breed Preference */}
        {isEditing ? (
          <LabeledInput
            label="Breed preference (optional)"
            value={breedPreference}
            onChange={setBreedPreference}
            placeholder="Dalmatian, Labrador, Cats"
          />
        ) : (
          <div>
            <p className="text-sm font-semibold text-gray-600">
              Breed preference
            </p>
            <p className="text-md">{application?.breed_preference || "None"}</p>
          </div>
        )}

        {/* Preferred Age Range */}
        <div className="mt-6">
          <p className="text-sm font-semibold mb-2">Preferred Age Range</p>
          {isEditing ? (
            <>
              <LabeledCheckBox
                onPress={() => {
                  setDogAgePreferenceLow(0);
                  setDogAgePreferenceHigh(2);
                }}
                isSelected={dogAgePreferenceLow === 0}
                value="0-2 years old"
              />
              <LabeledCheckBox
                onPress={() => {
                  setDogAgePreferenceLow(2);
                  setDogAgePreferenceHigh(5);
                }}
                isSelected={dogAgePreferenceLow === 2}
                value="2-5 years old"
              />
              <LabeledCheckBox
                onPress={() => {
                  setDogAgePreferenceLow(5);
                  setDogAgePreferenceHigh(15);
                }}
                isSelected={dogAgePreferenceLow === 5}
                value="5 years+"
              />
            </>
          ) : (
            <p className="text-md">
              {application?.animal_age_preference_low} -{" "}
              {application?.animal_age_preference_high} years old
            </p>
          )}
        </div>

        {/* Preferred Foster Duration */}
        <div className="mt-6">
          <p className="text-sm font-semibold mb-2">
            Preferred Foster Duration
          </p>
          {isEditing ? (
            <>
              <LabeledCheckBox
                label=""
                onPress={() => {
                  setPreferredFosterDurationLow(0);
                  setPreferredFosterDurationHigh(1);
                }}
                isSelected={preferredFosterDurationLow === 0}
                value="Less than 1 month"
              />
              <LabeledCheckBox
                label=""
                onPress={() => {
                  setPreferredFosterDurationLow(1);
                  setPreferredFosterDurationHigh(3);
                }}
                isSelected={preferredFosterDurationLow === 1}
                value="1-3 months"
              />
              <LabeledCheckBox
                label=""
                onPress={() => {
                  setPreferredFosterDurationLow(3);
                  setPreferredFosterDurationHigh(6);
                }}
                isSelected={preferredFosterDurationLow === 3}
                value="More than 3 months"
              />
            </>
          ) : (
            <p className="text-md">
              {application?.preferred_foster_duration_low} -{" "}
              {application?.preferred_foster_duration_high} months
            </p>
          )}
        </div>

        {/* Error Message */}
        {error && <p className="text-error text-sm mt-4">{error}</p>}

        {/* Submit Button */}
        {isEditing && (
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full mt-6 bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg ${
              isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
            }`}
          >
            Continue
          </button>
        )}
      </form>
    </div>
  );
};

export default FosterPreferences;
