import { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions } from "react-native-web";
import { logAnalyticsEvent } from "../../../services/firebaseService";

import Label from "../../../components/Label";
import { useParams } from "react-router-dom";
import { isMobile } from "../../../config/utils";
import { theme } from "../../../config/theme";

const FosterJourney = () => {
  const { id } = useParams();

  useEffect(() => {
    logAnalyticsEvent({
      eventName: "blog_detail_visited",
      data: { blogId: id },
    });
  }, [id]);

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require("../../../assets/images/dog-high-five.jpeg")}
      />
      <Label weight="medium" sz="xxlarge">
        A Foster Journey: How One Family Gave a Dog a Second Chance
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Beginning of a New Chapter
      </Label>
      <Label styles={styles.body} sz="medium">
        When the Johnson family decided to open their home to a foster dog, they
        knew they were embarking on a journey of love and transformation. They
        were experienced pet owners, with two cats and a dog of their own, but
        fostering was a new venture for them. They felt a calling to help
        animals in need and make a difference in their community.
      </Label>
      <Label styles={styles.body} sz="medium">
        Their journey began with a visit to the local animal shelter. The
        shelter was filled with dogs of all shapes and sizes, each with a
        heartbreaking story. But it was a scruffy, timid dog named Max that
        caught their attention. Max was a medium-sized terrier mix with soulful
        eyes that told a story of neglect and abandonment. He had been found
        wandering the streets, scared and hungry, and had been at the shelter
        for several months without any interest from potential adopters.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Decision to Foster
      </Label>
      <Label styles={styles.body} sz="medium">
        The Johnsons were immediately drawn to Max. They could see the potential
        for him to become a loving companion, and they wanted to give him a
        chance to thrive in a home environment. After discussing it as a family,
        they decided to bring Max home as their first foster dog.
      </Label>
      <Label styles={styles.body} sz="medium">
        Max was nervous when he first arrived at the Johnsons’ house. He had
        never known the comfort of a loving home, and every new sound and smell
        seemed to frighten him. But the Johnsons were patient. They gave Max his
        own space where he could feel safe and gradually introduced him to the
        other pets and family members.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        The Transformation Begins
      </Label>
      <Label styles={styles.body} sz="medium">
        Day by day, Max began to show signs of improvement. The once scared and
        skittish dog started to relax and show his true personality. He bonded
        quickly with the Johnsons’ dog, Bella, who seemed to understand that Max
        needed a friend. They played together in the backyard, and Bella’s
        confidence helped Max come out of his shell.
      </Label>

      <Label styles={styles.body} sz="medium">
        The Johnsons also worked on building Max’s trust with humans. They spent
        time sitting quietly with him, offering treats and gentle pets. Max
        began to seek out their company and even started wagging his tail when
        they came into the room. It was a small but significant step forward.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Celebrating Milestones
      </Label>

      <Label styles={styles.body} sz="medium">
        Every little milestone was a cause for celebration. The first time Max
        slept through the night without whining, the first time he ate his
        entire meal without hesitation, and the first time he playfully chased a
        ball in the yard—all these moments marked his progress.
      </Label>

      <Label styles={styles.body} sz="medium">
        Max’s biggest breakthrough came when he began to enjoy car rides. At
        first, he was terrified of getting into the car, associating it with the
        fear of the unknown. But with patience and positive reinforcement, he
        eventually hopped in eagerly, excited for the new adventures that
        awaited.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Finding a Furever Home
      </Label>

      <Label styles={styles.body} sz="medium">
        As Max blossomed in the Johnsons’ care, they knew it was time to start
        looking for his furever home. It was a bittersweet moment for the
        family. They had grown attached to Max and loved seeing his
        transformation, but they knew their role was to prepare him for his new
        family.
      </Label>

      <Label styles={styles.body} sz="medium">
        They created a profile for Max on several pet adoption websites,
        highlighting his loving nature and his journey of growth. It wasn’t long
        before they received interest from a family who seemed like the perfect
        match. The Martins were a loving couple with two young children and a
        spacious backyard. They had been searching for a dog who would fit into
        their active lifestyle and provide companionship for their kids.
      </Label>

      <Label styles={styles.body} sz="medium">
        After a successful meet-and-greet, the Martins decided to adopt Max. The
        Johnsons were thrilled but also emotional. Saying goodbye was hard, but
        they knew it was the beginning of a beautiful new chapter for Max.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        A Happy Ending
      </Label>

      <Label styles={styles.body} sz="medium">
        The Martins kept in touch with the Johnsons, sending regular updates and
        photos of Max. He had adjusted beautifully to his new home and was
        thriving with his new family. The once timid and neglected dog was now a
        confident and happy member of the Martins’ household, bringing joy and
        love to everyone he met.
      </Label>
      <Label styles={styles.body} sz="medium">
        The Johnsons’ experience with Max inspired them to continue fostering.
        They had seen firsthand the impact that love, patience, and a safe
        environment could have on a rescue dog. Each foster dog brought new
        challenges and rewards, but the ultimate goal was always the same: to
        give each dog a second chance at a happy life.
      </Label>

      <Label weight="bold" styles={styles.header} sz="large">
        Conclusion
      </Label>
      <Label styles={styles.body} sz="medium">
        Fostering animals is a rewarding and life-changing experience. It
        requires patience, love, and dedication, but the impact it has on the
        lives of both the animals and the foster families is immeasurable. Max’s
        story is just one example of how fostering can transform a dog’s life,
        turning fear into trust and abandonment into love.
      </Label>

      <Label styles={styles.body} sz="medium">
        If you’ve ever considered fostering, remember Max’s journey and the
        Johnsons’ experience. There are countless animals in shelters waiting
        for their chance to be part of a loving home. By opening your heart and
        home to a foster pet, you can make a world of difference, one paw at a
        time.
      </Label>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    marginBottom: 16,
    maxWidth: 1200,
    width: "100%",
    height: isMobile(Dimensions.get("window").width) ? 300 : 500,
    resizeMode: "cover",
    borderRadius: 8,
  },
  header: {
    paddingBottom: 16,
    paddingTop: 28,
  },
  body: {
    maxWidth: 1200,
    paddingBottom: 16,
    color: theme.colors.text.body,
  },
});

export default FosterJourney;
