import { useState } from "react";
import { StyleSheet, View, Dimensions } from "react-native-web";
import { theme } from "../../config/theme";
import Label from "../Label";
import LabeledInput from "../LabeledInput";
import Button from "../Button";
import { isEmailAddress } from "../../config/formatter";
import { submitContactRequest } from "../../services/apiService";

type Props = {};

const ContactForm = ({ ...rest }: Props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [didSucceed, setDidSucceed] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async () => {
    setDidSucceed(false);
    setError("");
    setIsLoading(true);

    if (!name) {
      setError("Please enter your name");
    }
    if (!isEmailAddress(email)) {
      setError("Invalid email address");
      return;
    }

    if (!message) {
      setError("You didn't write any information in the message box");
      return;
    }
    // Submit via mail api.
    const res = await submitContactRequest({ name, email, message });
    if (res.success) {
      setDidSucceed(true);
      setEmail("");
      setName("");
      setMessage("");
    } else {
      setError("Email failed to send. Please try again later.");
    }

    setTimeout(() => {
      setDidSucceed(false);
    }, 5000);
  };

  return (
    <View style={styles.container}>
      <Label styles={[styles.text]} sz="xxxlarge" weight="medium">
        Contact us
      </Label>

      <LabeledInput
        width={Dimensions.get("window").width - 40}
        label="Name"
        value={name}
        onChange={setName}
        placeholder="Your name"
      />
      <LabeledInput
        width={Dimensions.get("window").width - 40}
        label="Email"
        value={email}
        onChange={setEmail}
        placeholder="So we can get back to you"
      />
      <LabeledInput
        height={120}
        width={Dimensions.get("window").width - 40}
        multiline
        label="Message"
        value={message}
        onChange={setMessage}
        placeholder="Let us know how we can help"
      />
      <Label
        styles={{
          color: didSucceed ? theme.colors.secondary[300] : theme.colors.error,
        }}
        sz="small"
      >
        {didSucceed ? "Message sent! We will get back to you soon" : error}
      </Label>
      <Button
        backgroundColor="black"
        isDisabled={isLoading}
        width={Dimensions.get("window").width - 40}
        onPress={onSubmit}
      >
        Submit
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 800,
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    paddingBottom: 24,
  },
});

export default ContactForm;
