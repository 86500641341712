import React, { useEffect, useState } from "react";
import { logAnalyticsEvent } from "../../../services/firebaseService";
import {
  createAddress,
  createCampaign,
  fetchCampaignsForOrg,
  updateAddress,
} from "../../../services/apiService";
import { format } from "date-fns";
import { Address, Campaign } from "../../../types/types";
import { debounce } from "../../../config/utils";
import LabeledAddressInput from "../../../components/LabeledAddressInput";
import useOrganization from "../../../hooks/useOrganization";
import useAddress from "../../../hooks/useAddress";
import { useAuth } from "../../../hooks/AuthContext";
import LabeledInput from "../../../components/LabeledInput";
import ToolTip from "../../../components/ToolTip";

const Fosters = () => {
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const organization = useOrganization({});
  const fetchedAddress = useAddress(user?.uid);
  const [address, setAddress] = useState<Address | undefined>();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (fetchedAddress) setAddress(fetchedAddress);
  }, [fetchedAddress]);

  useEffect(() => {
    logAnalyticsEvent({ eventName: "rescue_fosters_visited" });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      if (isAtBottom) {
        setPageSize(pageSize + 20);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [pageSize]);

  const onCreateCampaign = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!organization) return;
    if (!subject || !message || !address) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await createCampaign({
        organization_id: organization.id,
        subject,
        message,
      });
      if (res.success) {
        setSubject("");
        setMessage("");
      }
    } catch (e) {
      console.warn("Failed");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        if (!organization || isLoading) return;
        const res = await fetchCampaignsForOrg(organization.id);
        setCampaigns(res.campaigns);
      } catch (e) {
        console.warn("failed to fetch campaigns");
      }
    };
    fetchCampaigns();
  }, [organization, isLoading]);

  return (
    <div className="p-5 min-h-screen">
      <h1 className="text-3xl font-bold text-secondary-400 mb-4">
        Find new fosters
      </h1>

      <div className="border border-secondary-100 shadow-md rounded-lg p-4 bg-white max-w-xl">
        <h1 className="text-lg font-semibold pt-2 pb-1">
          Reach Thousands of Foster Homes and Volunteers with Just One Click
        </h1>
        <ToolTip />
        {address?.city ? (
          <div className="mt-6 mb-1">
            <p className="text-md font-semibold">Active location</p>

            <div className="flex items-center">
              <p className="text-sm py-2">{`${address.city}, ${address.state}`}</p>

              <button
                onClick={() => setAddress(undefined)}
                className="text-sm font-medium text-secondary-400 underline ml-2"
              >
                Edit
              </button>
            </div>
          </div>
        ) : (
          <div className="pt-4 flex">
            <LabeledAddressInput
              label="Set your location to reach fosters nearby."
              value={{ label: "" }}
              onChange={(v) => {
                if (v) {
                  if (!address) {
                    createAddress({
                      ...v,
                      auth_user_id: user?.uid,
                    });
                  } else {
                    updateAddress({
                      ...v,
                      auth_user_id: user?.uid,
                    });
                  }
                  setAddress(v);
                }
              }}
              placeholder=""
            />
          </div>
        )}

        <form onSubmit={onCreateCampaign}>
          <LabeledInput
            label="Subject"
            value={subject}
            onChange={setSubject}
            placeholder="Set a campaign subject"
          />
          <LabeledInput
            height={150}
            multiline
            label="Message"
            value={message}
            onChange={(v) => {
              if (v.length <= 500) {
                setMessage(v);
              }
            }}
            placeholder="Write a message up to 600 characters long"
          />
          <p className="text-xs mb-1">{600 - message.length} characters left</p>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 mt-4 rounded-lg font-semibold text-lg ${
              isLoading
                ? "bg-tertiary text-gray-400 cursor-not-allowed"
                : "bg-black text-white hover:scale-105 transition-transform"
            }`}
          >
            {isLoading ? "Sending..." : "Create Campaign"}
          </button>
        </form>
      </div>

      <div className="max-w-xl">
        {campaigns?.length > 0 && (
          <h1 className="font-semibold text-lg mt-6"> Sent Campaigns</h1>
        )}
        {campaigns?.slice(0, pageSize).map((campaign, index) => (
          <div
            key={index}
            className="my-2 shadow-md bg-gray-100 p-4 rounded-lg mb-4"
          >
            {/* Display campaign information here if needed */}
            <p className="text-sm font-semibold">Subject</p>
            <p className="text-md mb-2">{campaign.subject}</p>
            <p className="text-sm font-semibold">Message</p>
            <p className="text-md">
              {campaign.message.length > 60
                ? `${campaign.message.slice(0, 60)}...`
                : campaign.message}
            </p>
            <p className="text-sm text-secondary-400 mt-2">
              <strong>
                Sent to {campaign.sent_count || 192} nearby fosters on{" "}
                {format(new Date(campaign.createdAt), "MMMM dd, yyyy")}
              </strong>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fosters;
