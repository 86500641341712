type Props = {
  icon?: JSX.Element;
  title: string;
  message: string;
  backgroundColor?: string;
  textColor?: string;
};

const InfoCard = ({
  icon,
  title,
  message,
  backgroundColor = "bg-white", // default background color
  textColor = "text-black", // default text color
}: Props) => {
  return (
    <div
      className={`flex flex-row justify-evenly items-center p-6 md:p-8 rounded-lg ${backgroundColor} max-w-full md:max-w-md lg:max-w-md`}
    >
      <div className="flex-shrink-0">{icon}</div>

      <div className="flex flex-col pl-4">
        <div className={`font-medium ${textColor} text-lg md:text-xl`}>
          {title}
        </div>
        <p className={`pt-2 ${textColor} text-sm md:text-base`}>{message}</p>
      </div>
    </div>
  );
};

export default InfoCard;
