import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { fetchAddress, fetchFoster } from "../../services/apiService";
import { Address, Foster } from "../../types/types";
import PersonalInformation from "../../components/ApplicationForm/PersonalInformation";
import Loading from "../../components/Loading";
import FosterPreferences from "../../components/ApplicationForm/FosterPreferences";
import HomeInformation from "../../components/ApplicationForm/HomeInformation";
import { logAnalyticsEvent } from "../../services/firebaseService";
import { useAuth } from "../../hooks/AuthContext";
import useOrganization from "../../hooks/useOrganization";

const FosterApplication = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const organizationId = params.get("organization") || undefined;
  const { user } = useAuth();
  const organization = useOrganization({ organizationId: organizationId });

  const [application, setApplication] = useState<Foster | undefined>(undefined);
  const [fosterAddress, setFosterAddress] = useState<Address | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const getApplication = useCallback(async () => {
    if (!id) return;

    logAnalyticsEvent({ eventName: "foster_application_viewed" });
    setIsLoading(true);

    try {
      const [res, addressRes] = await Promise.all([
        fetchFoster({ auth_user_id: id }),
        fetchAddress({ auth_user_id: id }),
      ]);

      if (res.foster) {
        setApplication(res.foster);
      }
      if (addressRes.address) {
        setFosterAddress(addressRes.address);
      }
    } catch (err) {
      console.error("Error fetching application or address:", err);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getApplication();
  }, [getApplication]);

  return isLoading ? (
    <Loading height={400} />
  ) : (
    <div className="flex flex-col w-full bg-secondary-100 p-6 lg:p-12 justify-center items-center">
      {!organization?.auth_user_id && (
        <div className="flex flex-col bg-white rounded-lg p-6 w-full max-w-xl justify-center items-center">
          <h1 className="text-xl font-semibold pb-2 text-center">
            Do You Need Additional Help?
          </h1>
          <p className="text-md pb-6 text-center">
            <span
              onClick={() => navigate("/")}
              className="underline text-secondary-400 cursor-pointer"
            >
              PetFosterz.com
            </span>{" "}
            connects you with a dedicated community ready to support you. Access
            our powerful tools to easily reach out and share updates, events,
            and urgent messages with our network of animal lovers—anytime, at no
            cost.
          </p>
          {/* Submit Button */}
          <button
            type="submit"
            onClick={() => {
              navigate(
                `/create-rescue-account?email=${organization?.email}&name=${organization?.name}`
              );
            }}
            className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg w-full max-w-lg"
          >
            Get Started
          </button>
        </div>
      )}

      <form className="my-6 rounded-lg bg-white flex flex-col items-center w-full max-w-xl p-6">
        <h1 className="text-3xl font-bold ">Applicant Profile</h1>
        {!organization?.auth_user_id && (
          <p className="pb-6 pt-2 text-sm text-center text-secondary-400">
            This profile is saved so you can access it anytime from your
            Petfosterz Dashboard.
          </p>
        )}

        <div className="w-full">
          <PersonalInformation
            isEditable={false}
            userId={user?.uid || ""}
            application={application}
          />

          <div className="h-1 w-full bg-secondary-200 rounded my-4"></div>

          <FosterPreferences
            isEditable={false}
            fosterAddress={fosterAddress}
            userId={application?.auth_user_id || ""}
            application={application}
          />

          <div className="h-1 w-full bg-secondary-200 rounded my-4"></div>

          <HomeInformation
            isEditable={false}
            userId={application?.auth_user_id || ""}
            application={application}
          />
        </div>
      </form>
    </div>
  );
};

export default FosterApplication;
