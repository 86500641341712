import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmailAddress } from "../../config/formatter";
import {
  createFosterUser,
  signInWithGooglePopup,
  logAnalyticsEvent,
} from "../../services/firebaseService";
import { createAddress, createFoster } from "../../services/apiService";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import LabeledInput from "../../components/LabeledInput";

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "signup_visited" });
  }, []);

  const onSubmitSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    logAnalyticsEvent({ eventName: "signup_submit" });

    if (!isEmailAddress(email)) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }
    const res = await createFosterUser(
      email.toLowerCase().trim(),
      password.trim()
    );
    if (res.success) {
      navigate("/update-account");
    } else {
      setError(res.error || "Failed");
    }
  };

  const onPressGoogle = async () => {
    const res = await signInWithGooglePopup();
    if (res?.user) {
      logAnalyticsEvent({ eventName: "contact_information_created" });
      await createAddress({ auth_user_id: res.user.uid });
      await createFoster({
        auth_user_id: res.user.uid,
        email: res.user.email?.toLowerCase() || "",
      });
      navigate("/update-account");
    }
  };

  return (
    <div className="flex flex-col items-center w-full bg-secondary-100 py-8">
      <div className="bg-white rounded-xl py-8 px-4 mx-6 max-w-sm md:max-w-xl">
        {/* Heading */}
        <h1 className="text-4xl font-bold text-center mb-4">Join Today!</h1>
        <p className="text-lg text-center mb-8">
          Become a part of the world's largest online community of pet fosters
          and make a difference for the many animals in need.
        </p>

        {/* Google Login Button */}
        <GoogleLoginButton borderWidth={1} onPress={onPressGoogle} />

        <p className="text-center text-lg my-4">OR</p>

        <form onSubmit={onSubmitSignup}>
          {/* Email and Password Inputs */}
          <LabeledInput
            label="Email"
            value={email}
            onChange={setEmail}
            placeholder="abc@email.com"
          />
          <LabeledInput
            isSecure
            label="Password"
            value={password}
            onChange={setPassword}
            placeholder="6 or more characters"
          />

          {/* Error Message */}
          {error && <p className="text-error text-sm mb-4">{error}</p>}

          {/* Submit Button */}
          <button
            type="submit"
            onClick={onSubmitSignup}
            className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg w-full max-w-lg"
          >
            Get Started
          </button>
        </form>

        {/* Login and Rescue Sign Up Links */}
        <p className="text-lg text-center mt-6">
          Already have an account?{" "}
          <span
            onClick={() => navigate("/login")}
            className="text-secondary-400 cursor-pointer"
          >
            Login
          </span>
        </p>
        <p
          onClick={() => navigate("/create-rescue-account")}
          className="text-secondary-400 text-center mt-6 underline cursor-pointer"
        >
          Sign up as a Rescue
        </p>
      </div>
    </div>
  );
};

export default Signup;
