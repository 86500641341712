import React, { useEffect, useState } from "react";
import { isEmailAddress } from "../../config/formatter";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../../components/LabeledInput";
import {
  createOrganizationUser,
  isAuthenticated,
} from "../../services/firebaseService";
import { logAnalyticsEvent } from "../../services/firebaseService";
import { setIsRescueAccount } from "../../config/utils";
import IconFosterFi from "../../assets/icons/IconFosterFi";

const SignupRescue = () => {
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const emailProp = params.get("email") || "";
  const nameProp = params.get("name") || "";
  const navigate = useNavigate();
  const [email, setEmail] = useState(emailProp);
  const [password, setPassword] = useState("");
  const [name, setName] = useState(nameProp);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isAuthed = isAuthenticated();

  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "rescue_signup_visited" });
    if (isAuthed && window.localStorage.getItem("IS_RESCUE")) {
      navigate("/rescue");
    }
  }, [isAuthed, navigate]);

  const onSubmitSignup = async (e: React.FormEvent) => {
    e.preventDefault();

    logAnalyticsEvent({ eventName: "rescue_signup_submit" });

    if (!name) {
      setError("Please enter your organization name");
      return;
    }
    if (!isEmailAddress(email)) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }

    setIsLoading(true);
    const res = await createOrganizationUser(
      email.toLowerCase().trim(),
      password.trim(),
      name
    );
    setIsLoading(false);
    if (res.success) {
      setIsRescueAccount(true);
      navigate("/rescue");
    } else {
      setError(res.error || "Failed");
    }
  };

  return (
    <div className="flex flex-col items-center w-full bg-secondary-100 py-8">
      <div className="bg-white rounded-xl py-8 px-4 mx-6 sm:max-w-xs md:max-w-xl">
        {/* Heading */}
        <div className="w-full flex flex-row space-x-5 items-center pb-4">
          <div className="flex flex-shrink-0">
            <IconFosterFi height={56} width={56} />
          </div>
          <h1 className="text-4xl font-semibold">FosterFi</h1>
        </div>

        {/* Benefits */}
        <div className="mb-6 space-y-2 text-left">
          <p className="text-md">
            🤝 Build lasting connections with a community of dedicated fosters.
          </p>
          {/* <p className="text-md">
            📊 Simplify expense tracking and reimbursement for foster
            caregivers. (coming soon!)
          </p> */}
          {/* <p className="text-md">
            🌟 Optimize your rescue operations with powerful management tools.
            (coming soon!)
          </p> */}
        </div>

        {/* Form */}
        <form onSubmit={onSubmitSignup}>
          <LabeledInput
            label="Organization name"
            value={name}
            onChange={setName}
            placeholder="Your organization"
          />
          <LabeledInput
            label="Email"
            value={email}
            onChange={setEmail}
            placeholder="abc@email.com"
          />
          <LabeledInput
            isSecure
            label="Password"
            value={password}
            onChange={setPassword}
            placeholder="6 or more characters"
          />
          {error && <p className="text-error text-sm mt-4">{error}</p>}

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full mt-6 bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg ${
              isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
            }`}
          >
            Join Now
          </button>
        </form>

        {/* Additional Links */}
        <p className="text-lg text-center mt-6">
          Already have an account?{" "}
          <span
            onClick={() => navigate("/login")}
            className="text-secondary-400 cursor-pointer"
          >
            Login
          </span>
        </p>
        {/* <p
          onClick={() => window.open("https://tryfosterfi.com", "_blank")}
          className="text-secondary-400 text-center mt-6 underline cursor-pointer"
        >
          Learn more
        </p> */}
      </div>
    </div>
  );
};

export default SignupRescue;
