import * as React from "react";
import Svg, { Circle, Ellipse, Path, Rect } from "react-native-svg";

type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const IconFosterFi = ({ width = 64, height = 64, fill = "#2F6B61" }: Props) => (
  <Svg width={width} height={height} viewBox="0 0 764 764" fill="none">
    <Rect width="764" height="764" rx="88" fill="black" />
    <Ellipse cx="381.645" cy="419.498" rx="161.645" ry="114.498" fill="white" />
    <Path
      d="M339.886 529.955L418.014 531.302L401.85 538.038C401.85 538.038 400.792 558.266 404.544 570.366C408.312 582.519 412.991 588.539 420.708 598.654C438.588 622.09 488.06 641.759 488.06 641.759C488.06 641.759 454.15 643.807 436.873 639.065C409.857 631.651 377.603 598.654 377.603 598.654C377.603 598.654 346.101 631.148 319.68 639.065C299.499 645.113 265.799 641.759 265.799 641.759C265.799 641.759 316.678 624.838 334.498 598.654C342.582 586.776 345.608 578.86 349.315 564.978C352.194 554.197 353.356 536.69 353.356 536.69L339.886 529.955Z"
      fill="white"
    />
    <Circle cx="192" cy="208" r="56" fill="white" />
  </Svg>
);

export default IconFosterFi;
