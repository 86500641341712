import {
  Address,
  Application,
  Campaign,
  Foster,
  Organization,
} from "../types/types";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

type CompleteResultResponse = {
  success: boolean;
  error?: string;
};

type CreateFosterParams = {
  auth_user_id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  is_adult?: boolean;
  phone?: string;
  breed_preference?: string | null;
  has_experience?: boolean | null;
  animal_age_preference_low?: number | null;
  animal_age_preference_high?: number | null;
  preferred_foster_duration_low?: number | null;
  preferred_foster_duration_high?: number | null;
  residence_type?: ResidenceType;
  residence_owned?: boolean | null;
  roommates_description?: string | null;
  existing_pets_description?: string | null;
  has_foster_experience?: boolean | null;
};

enum ResidenceType {
  house = "Home",
  townhouse = "Townhouse",
  apartment = "Apartment",
  condo = "Condo",
}

type UpdateFosterParams = {
  id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: Address;
  is_adult?: boolean;
  phone?: string;
  breed_preference?: string;
  has_experience?: boolean;
  animal_age_preference_low?: number;
  animal_age_preference_high?: number;
  preferred_foster_duration_low?: number;
  preferred_foster_duration_high?: number;
  residence_owned?: boolean;
  residence_type?: ResidenceType;
  roommates_description?: string;
  existing_pets_description?: string;
  has_foster_experience?: boolean;
};

type FetchFosterResponse = {
  foster: Foster;
  error?: string;
};

type FetchAllFostersResponse = {
  fosters: Foster[];
  error?: string;
};

type CreateApplicationParams = {
  foster_id: string;
  organization_name: string;
  organization_email: string;
  pet_name?: string;
  petfinder_id?: string;
  rescuegroup_id?: string;
  message?: string;
};

type UpdateApplicationParams = {
  application_id: string;
  was_viewed: boolean;
};

// Can be fetched by any organization OR foster
type FetchApplicationParams = {
  organization_id?: string;
  foster_id?: string;
};

type FetchApplicationsResponse = {
  applications?: Application[];
  error?: string;
};

type FetchFosterParams = {
  auth_user_id: string;
};

type FetchFosterByIdParams = {
  id: string;
};

type SubmitContactParams = {
  email: string;
  name?: string;
  message: string;
};

type CreateCampaignParams = {
  organization_id: string;
  subject: string;
  message: string;
};

type FetchCampaignsResponse = {
  campaigns: Campaign[];
  error?: string;
};

type CreateAddressParams = {
  auth_user_id: string;
  street_1?: string;
  street_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
};

type FetchAddressResponse = {
  address?: Address;
  error?: string;
};

type FetchOrganizationByEmailParams = {
  email: string;
};

type FetchOrganizationByExternalIdParams = {
  petfinder_id?: string;
  rescuegroup_id?: string;
};

type FetchOrganizationParams = {
  auth_user_id: string;
};

type FetchOrganizationByIdParams = {
  organization_id: string;
};

type FetchOrganizationResponse = {
  organization?: Organization;
  error?: string;
};

type CreateOrganizationParams = {
  id?: string;
  email: string;
  petfinder_id?: string;
  rescuegroup_id?: string;
  auth_user_id?: string;
  name?: string;
};

export const createFoster = async ({
  auth_user_id,
  email,
}: CreateFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        email,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const createAddress = async ({
  auth_user_id,
  street_1,
  street_2,
  city,
  state,
  postal_code,
  country,
  latitude,
  longitude,
}: CreateAddressParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        street_1,
        street_2,
        city,
        state,
        postal_code,
        country,
        latitude,
        longitude,
      }),
    });

    if (!response.ok) {
      console.log(response.status, response);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const updateAddress = async ({
  auth_user_id,
  street_1,
  street_2,
  city,
  state,
  postal_code,
  country,
  latitude,
  longitude,
}: CreateAddressParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses/${auth_user_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_user_id,
        street_1,
        street_2,
        city,
        state,
        postal_code,
        country,
        latitude,
        longitude,
      }),
    });

    if (!response.ok) {
      console.log(response.status, response.json);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const fetchAddress = async ({ auth_user_id }: FetchFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/addresses/${auth_user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const address: Address = data;
    return { address } as FetchAddressResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchAddressResponse;
  }
};

export const updateFoster = async ({
  id,
  email,
  first_name,
  last_name,
  address,
  is_adult,
  phone,
  breed_preference,
  has_experience,
  animal_age_preference_low,
  animal_age_preference_high,
  preferred_foster_duration_low,
  preferred_foster_duration_high,
  residence_owned,
  roommates_description,
  existing_pets_description,
  has_foster_experience,
}: UpdateFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        email,
        first_name,
        last_name,
        address,
        is_adult,
        phone,
        breed_preference,
        has_experience,
        animal_age_preference_low,
        animal_age_preference_high,
        preferred_foster_duration_low,
        preferred_foster_duration_high,
        residence_owned,
        roommates_description,
        existing_pets_description,
        has_foster_experience,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data.id;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const fetchFoster = async ({ auth_user_id }: FetchFosterParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/${auth_user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.log(response.status);
      return { error: response.statusText } as FetchFosterResponse;
    }
    const data = await response.json();
    return { foster: data } as FetchFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchFosterResponse;
  }
};

export const fetchFosterById = async ({ id }: FetchFosterByIdParams) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters/id/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      console.log(response.status);
      return { error: response.statusText } as FetchFosterResponse;
    }
    const data = await response.json();
    return { foster: data } as FetchFosterResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchFosterResponse;
  }
};

export const fetchAllFosters = async (lat: number, lon: number) => {
  try {
    const response = await fetch(`${BASE_URL}/fosters?lat=${lat}&lon=${lon}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return { error: response.statusText } as FetchAllFostersResponse;
    }
    const data = await response.json();
    return { fosters: data } as FetchAllFostersResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchAllFostersResponse;
  }
};

export const createApplication = async ({
  foster_id,
  organization_email,
  organization_name,
  pet_name,
  petfinder_id,
  rescuegroup_id,
  message,
}: CreateApplicationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/applications/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        foster_id,
        organization_email,
        organization_name,
        pet_name,
        petfinder_id,
        rescuegroup_id,
        message,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const updateApplication = async ({
  application_id,
  was_viewed,
}: UpdateApplicationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/applications/${application_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        was_viewed,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = !!data;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const fetchApplications = async ({
  organization_id,
}: FetchApplicationParams) => {
  try {
    // Fetch organizations by organization ID
    const response = await fetch(
      `${BASE_URL}/applications/organization/${organization_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    const applications: Application[] = data;
    return { applications } as FetchApplicationsResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error } as FetchApplicationsResponse;
  }
};

export const fetchApplicationsForFoster = async ({
  foster_id,
}: FetchApplicationParams) => {
  try {
    // Fetch organizations by organization ID
    const response = await fetch(
      `${BASE_URL}/applications/foster/${foster_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      console.log(response.status);
      return { error: "No applications found" } as FetchApplicationsResponse;
    }
    const data = await response.json();
    const applications: Application[] = data;
    return { applications } as FetchApplicationsResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error } as FetchApplicationsResponse;
  }
};

export const submitContactRequest = async ({
  email,
  name,
  message,
}: SubmitContactParams) => {
  try {
    const response = await fetch(`${BASE_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        message,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const success: boolean = data.success;
    return { success } as CompleteResultResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error: error, success: false } as CompleteResultResponse;
  }
};

export const fetchOrganization = async ({
  auth_user_id,
}: FetchOrganizationParams) => {
  try {
    // fetch by petfinder_id else auth user id
    let url = `${BASE_URL}/organizations/${auth_user_id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchOrganizationById = async ({
  organization_id,
}: FetchOrganizationByIdParams) => {
  try {
    // fetch by org id
    let url = `${BASE_URL}/organizations/id/${organization_id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchOrganizationByEmail = async ({
  email,
}: FetchOrganizationByEmailParams) => {
  try {
    // fetch by org id
    let url = `${BASE_URL}/organizations/email/${email}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchOrganizationByExternalId = async ({
  petfinder_id,
  rescuegroup_id,
}: FetchOrganizationByExternalIdParams): Promise<FetchOrganizationResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/organizations/${
        petfinder_id ? "petfinder" : "rescuegroup"
      }/${petfinder_id ? petfinder_id : rescuegroup_id}`
    );
    return { organization: response.data } as FetchOrganizationResponse;
  } catch (error) {
    return { error } as FetchOrganizationResponse;
  }
};

export const createOrganization = async ({
  auth_user_id,
  email,
  name,
  petfinder_id,
  rescuegroup_id,
}: CreateOrganizationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        auth_user_id,
        petfinder_id,
        rescuegroup_id,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const updateOrganization = async ({
  id,
  auth_user_id,
  email,
  name,
}: CreateOrganizationParams) => {
  try {
    const response = await fetch(`${BASE_URL}/organizations/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        auth_user_id,
        name,
      }),
    });

    if (!response.ok) {
      console.log(response.status);
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const organization: Organization = data;
    return { organization } as FetchOrganizationResponse;
  } catch (error) {
    console.error("Error:", error);
    return { error } as FetchOrganizationResponse;
  }
};

export const fetchDistanceInMiles = async (
  origin: string,
  destination: string
): Promise<number> => {
  try {
    const response = await axios.get(
      "https://maps.googleapis.com/maps/api/distancematrix/json",
      {
        params: {
          origins: origin,
          destinations: destination,
          key: process.env.REACT_APP_MAPS_KEY,
        },
      }
    );
    const distanceInMeters = response.data.rows[0].elements[0].distance.value;
    const distanceInMiles = distanceInMeters / 1609.34; // Convert meters to miles

    return distanceInMiles;
  } catch (e) {
    return -1;
  }
};

export const createCampaign = async (
  params: CreateCampaignParams
): Promise<CompleteResultResponse> => {
  try {
    const response = await axios.post(`${BASE_URL}/campaigns`, {
      ...params,
    });
    if (!response.data) {
      throw new Error("Network response was not ok");
    }
    return { success: true } as CompleteResultResponse;
  } catch (error) {
    return { error } as CompleteResultResponse;
  }
};

export const fetchCampaignsForOrg = async (
  organizationId: string
): Promise<FetchCampaignsResponse> => {
  try {
    const response = await axios.get(`${BASE_URL}/campaigns/${organizationId}`);
    return { campaigns: response.data } as FetchCampaignsResponse;
  } catch (error) {
    return { error } as FetchCampaignsResponse;
  }
};
