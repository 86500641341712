import React, { useEffect, useCallback, useRef } from "react";
import Loading from "../../components/Loading";
import { debounce } from "../../config/utils";
import OrgCard from "../../components/OrgCard";
import useFetchPetFinderOrganizations from "../../hooks/useFetchPetFinderOrganizations";

const Organizations = () => {
  const location = window.localStorage.getItem("LOCATION") || "33101";

  const { organizationsList, isLoading, fetchData, loadNextPage } =
    useFetchPetFinderOrganizations(location, "");

  // Ref to track if it's the first render
  const isFirstRender = useRef(true);

  // Debounced search function to avoid triggering too many API calls

  // Fetch data initially when component mounts, but skip debounce on first render
  useEffect(() => {
    if (isFirstRender.current) {
      fetchData(); // Ensure the initial fetch happens
      isFirstRender.current = false; // Mark the first render as done
    }
  }, [fetchData]);

  // Infinite scrolling handler
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    if (isAtBottom && !isLoading) {
      loadNextPage(); // Trigger the next page load
    }
  }, [isLoading, loadNextPage]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 400);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="bg-primary min-h-screen flex flex-col items-center">
      <div className="mt-4 mb-4 w-full max-w-xl mx-auto px-4" />

      <div className="flex justify-center w-full px-4">
        <div className="w-full lg:w-3/5 flex flex-wrap justify-center">
          {organizationsList.map((org, i) => {
            const formattedName = org.name
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" ");
            const orgImageUrl = org.photos.find((p) => p.medium)?.medium;
            return (
              <OrgCard
                key={i}
                org={org}
                imageUrl={orgImageUrl}
                name={formattedName}
                distance={org.distance}
              />
            );
          })}

          {isLoading && <Loading height={100} />}

          {organizationsList.length === 0 && !isLoading && (
            <p className="text-md text-center p-4">
              We're currently experiencing high traffic. Please check back
              later! 😕
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Organizations;
