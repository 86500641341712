import { useState, useCallback, useRef } from "react";
import { Animal } from "../types/types";
import { fetchPetFinderAnimals } from "../services/petfinderService";

const useFetchPets = (
  location: string,
  breed: string,
  petType: string,
  page: string
) => {
  const [petsList, setPetsList] = useState<Animal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const cache = useRef<{ [key: string]: Animal[] }>({});

  const fetchData = useCallback(
    async (forcePage?: string) => {
      setIsLoading(true);
      setError("");
      const pageToUse = forcePage || page;
      const cacheKey = `${location}-${breed}-${petType}-${pageToUse}`;

      // Check cache first to prevent unnecessary API requests
      if (cache.current[cacheKey]) {
        setPetsList(cache.current[cacheKey]);
        setIsLoading(false);
        return;
      }
      if (!location) {
        setIsLoading(false);
        setError('Enable your location or sign up now to find foster pets nearby.')
        return;
      }

      try {
        const res = await fetchPetFinderAnimals({
          location: location,
          breed,
          type: petType !== "All pets" ? petType : "",
          page: pageToUse,
        });
        if (res?.animals) {
          const newPetsList =
            Number(pageToUse) > 1
              ? [...petsList, ...res.animals]
              : res.animals;
          setPetsList(newPetsList);
          cache.current[cacheKey] = newPetsList; // Cache the result
        } else if (pageToUse === "1") {
          setPetsList([]);
          setError("No results were found in your area. Try checking back later.");
        }
      } catch (err) {
        setError("Failed to fetch animals. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [location, breed, petType, page, petsList]
  );

  return { petsList, isLoading, error, fetchData };
};

export default useFetchPets;
