import React, { useEffect, useState } from "react";
import { isEmailAddress } from "../../config/formatter";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../../components/LabeledInput";
import {
  auth,
  loginUser,
  signInWithGooglePopup,
} from "../../services/firebaseService";
import { logAnalyticsEvent } from "../../services/firebaseService";
import GoogleLoginButton from "../../components/GoogleLoginButton";
import {
  fetchFoster,
  fetchOrganization,
  fetchOrganizationByEmail,
  updateOrganization,
} from "../../services/apiService";
import { setIsRescueAccount } from "../../config/utils";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    logAnalyticsEvent({ eventName: "login_visited" });
  }, []);

  const onSubmitLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isEmailAddress(email.trim())) {
      setError("Invalid email address");
      return;
    }
    if (!password || password.length < 6) {
      setError("Password must be 6 or more characters");
      return;
    }

    setIsLoading(true);

    const res = await loginUser(email.trim(), password);
    if (res.success) {
      if (auth.currentUser) {
        const res = await fetchFoster({ auth_user_id: auth.currentUser?.uid });
        if (res.foster) {
          setIsRescueAccount(false);
          navigate("/update-account");
        } else {
          const orgRes = await fetchOrganization({
            auth_user_id: auth.currentUser?.uid,
          });
          if (orgRes.organization) {
            setIsRescueAccount(true);
            navigate("/rescue");
          }
        }
      }
    } else {
      setError(res.error || "Failed");
    }
    setIsLoading(false);
  };

  const onPressGoogle = async () => {
    const res = await signInWithGooglePopup();
    if (res?.user) {
      const fosterRes = await fetchFoster({ auth_user_id: res.user.uid });
      if (fosterRes.foster) {
        navigate("/update-account");
      } else {
        // No foster, check if it's an organization logging in...
        if (!res.user.email) {
          //should never happen... but anyways
          setError(
            "Failed. Please try another login method or contact support."
          );
          return;
        }
        const orgRes = await fetchOrganizationByEmail({
          email: res.user.email,
        });
        if (orgRes.organization) {
          if (!orgRes.organization.auth_user_id) {
            // set auth id if not set
            updateOrganization({
              id: orgRes.organization.id,
              email: res.user.email,
              auth_user_id: res.user.uid,
            });
          }
          setIsRescueAccount(true);
          navigate("/rescue");
        } else {
          // No application, no rescue, must be accidental sign up on login pag
          setError(
            "No account found. Please try signing up or reaching out to our support team for further assistance."
          );
        }
      }
    }
  };

  return (
    <div className="flex flex-col items-center w-full bg-secondary-100 py-8">
      <div className="bg-white rounded-xl py-8 px-4 mx-6 max-w-sm md:max-w-xl">
        {/* Heading */}
        <h1 className="text-4xl font-bold text-center mb-4">Welcome back!</h1>
        <p className="text-lg text-center mb-8">
          Log in to access your account and view your dashboard.
        </p>

        {/* Google Login Button */}
        <GoogleLoginButton borderWidth={1} onPress={onPressGoogle} />

        <p className="text-center text-lg my-4">OR</p>

        <form onSubmit={onSubmitLogin}>
          {/* Email and Password Inputs */}
          <LabeledInput
            label="Email"
            value={email}
            onChange={setEmail}
            placeholder="Enter your email"
          />
          <LabeledInput
            isSecure
            label="Password"
            value={password}
            onChange={setPassword}
            placeholder="******"
          />

          {/* Error Message */}
          {error && <p className="text-error text-sm mb-4">{error}</p>}

          {/* Submit Button */}
          <button
            disabled={isLoading}
            type="submit"
            className="bg-black text-white font-semibold text-lg py-2 px-6 rounded-lg w-full"
          >
            Log in
          </button>
        </form>

        {/* Sign Up and Rescue Account Links */}
        <p className="text-lg text-center mt-6">
          Just getting started?{" "}
          <span
            onClick={() => navigate("/create-account")}
            className="text-secondary-400 cursor-pointer"
          >
            Sign up now
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
